import { ADD_ACCOUNT_DETAILS, SELECTED_ACCOUNT, SET_AGGREGATES, TOTAL_LIFETIME_EARNINGS } from "../ActionTypes";

const initialState = {
  selectedAccount: {},
  accounts: [],
  totalLifeTimeEarning:0,
  aggregates:[]
};

const accountsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ACCOUNT_DETAILS:
      {
        return {
          ...state,
          accounts: action.payload,
        };
      }
    case SELECTED_ACCOUNT:{
      localStorage.setItem("accountId",action.payload.accountId)
      return {
        ...state,
        selectedAccount: action.payload,
      };
    }

    case TOTAL_LIFETIME_EARNINGS:
      return {
        ...state,
        totalLifeTimeEarning: action.payload
      }  

    case SET_AGGREGATES: 
      return {
        ...state,
        aggregates: action.payload
      }
    default:
      return state;
  }
};

export default accountsReducer;
