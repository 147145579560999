import cashback from "../assets/images/cb.svg";
import reward from "../assets/images/Rewards.svg";
import lounge from "../assets/images/Lounge.svg";
import feeReversal from "../assets/images/FeeReversal.svg";
import fuel from "../assets/images/Fuel.svg";
import voucher from "../assets/images/Voucher.svg";

export const offerTypes = {
  1282: {
    name: "Cashbacks & Discounts",
    icon: cashback,
    iconColor: "#D7E0FA",
    redirect: "/cashback",
  },
  1283: {
    name: "Fuel Surcharge Waive",
    icon: fuel,
    iconColor: "#D7D7FA",
    redirect: "/fuel",
  },
  1284: {
    name: "Fee Reversal",
    icon: feeReversal,
    iconColor: "#FADCEB",
    redirect: "/feeReversal",
  },
  //   1282: {
  //     name: "Reward Points",
  //     icon: reward,
  //     iconColor: "#EDD7FA",
  //     redirect: "/cashback",
  //   },
  //   1282: {
  //     name: "Vouchers",
  //     icon: voucher,
  //     iconColor: "#FAE5D7",
  //     redirect: "/cashback",
  //   },
  //   1282: {
  //     name: "Lounge Benefits ",
  //     icon: lounge,
  //     iconColor: "#FAD7D7",
  //     redirect: "/cashback",
  //   },
};
export const BenefitTypes = Object.freeze({
  REWARDS: {
    value: "REWARDS",
    name: "Reward Points",
    icon: reward,
    iconColor: "#EDD7FA",
    redirect: "/rewards",
  },
  CASHBACK_DISCOUNT: {
    value: "CASHBACK_DISCOUNT",
    name: "Cashbacks & Discounts",
    icon: cashback,
    iconColor: "#D7E0FA",
    redirect: "/cashback",
  },
  VOUCHER: {
    value: "VOUCHER",
    name: "Vouchers",
    icon: voucher,
    iconColor: "#FAE5D7",
    redirect: "/vouchers",
  },
  LOUNGE: {
    value: "LOUNGE",
    name: "Lounge Benefits ",
    icon: lounge,
    iconColor: "#FAD7D7",
    redirect: "/lounge",
  },
  FUEL_SURCHARGE_WAIVER: {
    value: "FUEL_SURCHARGE_WAIVER",
    name: "Fuel Surcharge Waiver",
    icon: fuel,
    iconColor: "#D7D7FA",
    redirect: "/fuel",
  },
  FEE_REVERSAL: {
    value: "FEE_REVERSAL",
    name: "Fee Reversal",
    icon: feeReversal,
    iconColor: "#FADCEB",
    redirect: "/feeReversal",
  },
});

export const DATES = Object.freeze({
  LIFETIME_BENEFIT_DATE: "2020-12-01",
});

export const LOUNGE_LIST_URL = Object.freeze({
  PRIORITY:
    "https://www.aubank.in/Priority%20Pass%20Lounges%20PDF-Priority%20Pass%20Lounges.pdf",
  DOMESTIC_ARPPORT:
    "https://info.dreamfolks.in/partner-lounges?filter=QXAxaXdab1gvQWRXTE5KRWNXMkNNUT09&service_id=alVML1JDTEswb2FhR2xYTFVRS2M3dz09",
  DOMESTIC_RAILWAY:
    "https://www.aubank.in/Railway%20Lounges%20PDF-Railway%20Lounges.pdf",
});

export const ACCOUNT_STATUS = Object.freeze({
  TRANSFERRED: "TRANSFERRED",
  ACTIVE: "ACTIVE",
  BLOCKED: "BLOCKED",
});
