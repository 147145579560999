import React, { useEffect, useRef, useState } from "react";
import "./styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import left from "../../assets/images/Left.svg";
import fee from "../../assets/images/FeeReversal.svg";
import PublicService from "../../services/PublicService";
import moment from "moment";
import Select, { components } from "react-select";
import { formatRewardValue, isSessionExpired, truncate } from "../../utils/functions";
import cb from "../../assets/images/FeeReversal.svg";
import { BenefitTypes, DATES } from "../../utils/constants";
import TransactionsShimmer from "../../components/shimmer/transactionsShimmer/transactionsShimmer";
import { useDispatch, useSelector } from "react-redux";
import sortDown from "../../assets/images/SortDown.svg";
import { resetSessionStore } from "../../store/actions/Session";
import infoIcon from "../../assets/images/info-circle.svg";
import FeeReversalLogo from "../../assets/images/FeeReversal";

const FeeReversal = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [session] = useSelector((state) => [state.session]);
  const [change, setChange] = useState(false);
  const [descOpen, setDescOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [showToTop, setShowToTop] = useState(false);
  const [timeBasedEarnings, setTimeBasedEarnings] = useState(0);
  const [startDate, setStartDate] = useState(
    moment().subtract(3, "months").format("YYYY-MM-DD")
  );
  const [currEndDate, setCurrEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [value, setValue] = useState(3);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [timePeriodCount, setTimePeriodCount] = useState(0);
  const [dataFoundOrLifeTime, setDataFoundOrLifeTime] = useState(false) 

  const timePeriodOptions = [
    { value: "3", label: "3 Months" },
    { value: "6", label: "6 Months" },
    { value: "12", label: "12 Months" },
    { value: "60", label: "Lifetime" },
  ];

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={sortDown} />
      </components.DropdownIndicator>
    );
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      if(session?.sessionLoading){
        return false;
      }
      try {
        const resp = await PublicService.fetchTransactions({
          tag: "FEE_REVERSAL",
          id: session.summary.aggregates[5].id,
          accountId: localStorage.getItem("accountId"),
          page: currPage,
          startDate: startDate,
          endDate: currEndDate,
        });
        if(isSessionExpired(resp)){
          navigate("/sessionExpired");
          dispatch(
            resetSessionStore()
          );
          return false;
        }
        if (resp.status === 200) {

          if (!dataFoundOrLifeTime) {
            if (value <= 6) {
              if (resp?.data?.data?.count == 0) {
                setStartDate(
                  moment(moment().format("YYYY-MM-DD"))
                    .subtract(value * 2, "months")
                    .format("YYYY-MM-DD")
                )
                setValue(value * 2);
                setTimePeriodCount(timePeriodCount + 1);
              }
              else {
                setDataFoundOrLifeTime(true)
              }
            }
            else if(resp?.data?.data?.count == 0){
              setValue(value * 2);
              setTimePeriodCount(timePeriodCount + 1);
              setStartDate(moment(DATES.LIFETIME_BENEFIT_DATE).format("YYYY-MM-DD"));
              setDataFoundOrLifeTime(true)
            }
            else{
              setDataFoundOrLifeTime(true)
            }
          }

          setTransactions([...transactions, ...resp.data.data.data]);

          setTimeBasedEarnings(parseFloat(resp.data.data.totalValue));
          if (resp.data.data.hasNextPage) {
            setCurrPage(currPage + 1);
          }
          else
            setLoading(false);
        } else{
          navigate("/error", {
            state: {
              x_correlation_id: resp.headers["x-correlation-id"],
            },
          });
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [startDate, change, currPage, session.sessionLoading]);

  const back = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const setBacktoTopShow = () => {
    if (window.scrollY > 850) {
      setShowToTop(true);
    } else {
      setShowToTop(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", setBacktoTopShow);
  }, []);

  return (
    <div className="main">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          maxWidth: "450px",
          backgroundColor: "#FADCEB",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "16px",
          }}
        >
          <div style={{ margin: "0 8px" }} onClick={() => navigate(-1)}>
            <img src={left} height={"18px"} width={"18px"} alt="" />
          </div>
          <div className="header">Fee Reversal</div>
        </div>
        <div></div>
      </div>
      <div
        style={{
          height: "60px",
          width: "100%",
          maxWidth: "450px",
          backgroundColor: "#FADCEB",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            padding: "20px",
            background: "#ffffff",
            boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.08)",
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            width: "80%",
            marginTop: "15%",
          }}
        >
          <div>
            <FeeReversalLogo color1={"#DC81AF"} />
          </div>
          <div style={{ marginLeft: "24px", textAlign: "left" }}>
            <div className="desc">Lifetime earnings</div>
            <div className="text-xl">
              {formatRewardValue(
                parseFloat(state.currentValue),
                BenefitTypes.CASHBACK_DISCOUNT.value
              )}
            </div>
          </div>
        </div>
      </div>
      {showToTop && (
        <div
          style={{
            position: "fixed",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            maxWidth: "450px",
          }}
        >
          <div
            style={{
              padding: "8px 24px",
              backgroundColor: "#F37435",
              borderRadius: "32px",
              color: "#fff",
              fontSize: "14px",
              marginTop: "18px",
              fontFamily: "Quicksand",
              fontWeight: "700",
              lineHeight: "22px",
              textTransform: "capitalize",
              cursor: "pointer",
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.16)",
            }}
            onClick={() => back()}
          >
            Back To Top
          </div>
        </div>
      )}

      <div
        style={{
          height: "60px",
          width: "100%",
          maxWidth: "450px",
          marginTop: "20%",
          backgroundColor: "#fff",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "16px 16px 0 16px",
            backgroundColor: "#fff",
          }}
        >
          <div
            className="heading-sm"
            style={{ fontWeight: "700", color: "#303030" }}
          >
            History
          </div>
          {dataFoundOrLifeTime && (
            <div className="heading-sm">
              <Select
                defaultValue={timePeriodOptions[timePeriodCount]}
                className="offers-dropdown"
                classNamePrefix={"offers-dropdown-child"}
                components={{ DropdownIndicator }}
                placeholder="3 Months"
                isSearchable={false}
                onChange={(e) => {
                  e.value <= 12
                    ? setStartDate(
                        moment(moment().format("YYYY-MM-DD"))
                          .subtract(e.value, "months")
                          .format("YYYY-MM-DD")
                      )
                    : setStartDate(moment(DATES.LIFETIME_BENEFIT_DATE).format("YYYY-MM-DD"));
                  setCurrEndDate(moment().format("YYYY-MM-DD"));
                  setCurrPage(1);
                  setValue(e.value);
                  setTimeBasedEarnings(null);
                  setChange(!change);
                  setTransactions([]);
                }}
                options={timePeriodOptions}
              />
            </div>
          )}
        </div>

        <div>
          {loading ? (
            <div>
              <TransactionsShimmer />
            </div>
          ) : (
            <>
              {timeBasedEarnings > 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "16px 16px 0 16px",
                    backgroundColor: "#fff",
                  }}
                >
                  <div
                    className="heading-sm"
                    style={{
                      fontWeight: "700",
                      fontSize: "12px",
                      color: "#636D7D",
                    }}
                  >
                    {value > 12
                      ? "Lifetime Earnings"
                      : `Past ${value} Months Earnings`}
                  </div>
                  <div className="timeBasedEarnings-amount heading-sm">
                    {formatRewardValue(timeBasedEarnings)}
                  </div>
                </div>
              )}

              <div className="note-section-div">
                <hr className="note-section-hr-line"></hr>

                <div className="note-section">
                  <img className="note-section-info-icon" src={infoIcon} />
                  <span className="note-section-info-text">
                    Transactions updates take upto 48 hours
                  </span>
                </div>
              </div>

              {transactions.length > 0 ? (
                transactions.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      textAlign: "left",
                      padding: "16px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <div
                      style={{ width: "75%" }}
                      onClick={() => setDescOpen(!descOpen)}
                    >
                      <div className="text-desc">
                        {descOpen
                          ? item.description
                          : truncate(item.description, 40)}
                      </div>
                      <div className="text-desc" style={{ fontWeight: "500" }}>
                        {moment(item.postingDate).format("DD MMM 'YY")}
                      </div>
                    </div>
                    <div
                      className="text-desc"
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        color:
                          item.moneyMovementIndicator === "cr"
                            ? "#4CAD6D"
                            : "#23212C",
                        textAlign: "right",
                        width: "120px",
                      }}
                    >
                      {item.moneyMovementIndicator === "cr"
                        ? `+ ${formatRewardValue(
                            parseFloat(item.value),
                            BenefitTypes.FEE_REVERSAL.value
                          )}`
                        : `- ${formatRewardValue(
                            parseFloat(item.value),
                            BenefitTypes.FEE_REVERSAL.value
                          )}`}
                    </div>
                  </div>
                ))
              ) : (
                <div style={{ width: "inherit" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      margin: "40% 0",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        padding: "10px",
                        borderRadius: "100%",
                        border: "2px solid #000",
                      }}
                    >
                      <img src={cb} height={"32px"} width={"32px"} />
                    </div>
                    <div
                      style={{
                        marginTop: "8px",
                        fontSize: "20px",
                        fontFamily: "Quicksand",
                        fontWeight: "700",
                        lineHeight: "28px",
                      }}
                    >
                      No Transaction has been done in this window
                    </div>
                  </div>
                </div>
              )}
              {transactions.length > 0 && !loading && (
                <div className="transactions_end">End Of History</div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeeReversal;
