import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "../pages/home/home";
import Summary from "../pages/summary/summary";
import Cashback from "../pages/cashback/cashback";
import Fuel from "../pages/fuel/fuel";
import FeeReversal from "../pages/feeReversal/feeReversal";
import Rewards from "../pages/rewards/rewards";
import Offers from "../pages/offers/offers";
import Voucher from "../pages/vouchers/vouchers";
import OfferDetails from "../pages/offerDetails/offerDetails";
import Login from "../pages/login/login";
import VerifyOtp from "../pages/verifyOtp/verifyOtp";
import QualifyingTransactions from "../pages/qualifyingTransactions/qualifyingTransactions";
import Lounge from "../pages/lounge/lounge";
import Error from "../pages/error/error";
import SessionExpired from "../pages/sessionExpired/sessionExpired";
import UnavailableCard from "../pages/error/UnavailableCard";

const MainRoutes = () => {
  const location = useLocation();
  return (
    <Routes>
      <Route path="*" element={<Home />} />
      <Route path="/summary" element={<Summary />} />
      <Route path="/error" element={<Error />} />
      <Route path="/cashback" element={<Cashback />} />
      <Route path="/fuel" element={<Fuel />} />
      <Route path="/lounge" element={<Lounge />} />
      <Route path="/rewards" element={<Rewards />} />
      <Route path="/feeReversal" element={<FeeReversal />} />
      <Route path="/offers" element={<Offers />} />
      <Route path="/vouchers" element={<Voucher />} />
      <Route path="/offerDetails" element={<OfferDetails />} />
      <Route path="/login" element={<Login />} />
      <Route path="/verifyOtp" element={<VerifyOtp />} />
      <Route path="/sessionExpired" element={<SessionExpired />} />
      <Route path="/unavailableCard" element={<UnavailableCard />} />
      <Route
        path="/qualifyingTransactions"
        element={<QualifyingTransactions />}
      />
    </Routes>
  );
};

export default MainRoutes;
