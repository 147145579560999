import React from 'react'
import './styles.css'
import { ShimmerButton, ShimmerCategoryItem, ShimmerCircularImage, ShimmerText, ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects'

const OffersShimmer = () => {
  return (
    <div className='offersShimmerContainer'>
        <ShimmerTitle line={1}/>
        <div className='offersButtonsShimmerContainer'>
            <div>
                <ShimmerButton/>
            </div>
            <div>
                <ShimmerButton/>
            </div>
            <div>
                <ShimmerButton/>
            </div>
        </div>
        <div className='offersRewardShimmerContainer'>
            <div className='offersRewardShimmer'>
                <div>
                    <ShimmerCircularImage className={'offersOffersCircleShimmer'} size={50}/>
                </div>
                <div className='offersRewardTextShimmer'>
                    <div>
                        <ShimmerTitle className={'offersOffersTitleShimmer'} line={1}/>
                    </div>
                    <div>
                    <   ShimmerText line={1}/>
                    </div>
                </div>
            </div>
            <div className='offersRewardShimmer'>
                <div>
                    <ShimmerCircularImage className={'offersOffersCircleShimmer'} size={50}/>
                </div>
                <div className='offersRewardTextShimmer'>
                    <div>
                        <ShimmerTitle className={'offersOffersTitleShimmer'} line={1}/>
                    </div>
                    <div>
                    <   ShimmerText line={1}/>
                    </div>
                </div>
            </div>
            <div className='offersRewardShimmer'>
                <div>
                    <ShimmerCircularImage className={'offersOffersCircleShimmer'} size={50}/>
                </div>
                <div className='offersRewardTextShimmer'>
                    <div>
                        <ShimmerTitle className={'offersOffersTitleShimmer'} line={1}/>
                    </div>
                    <div>
                    <   ShimmerText line={1}/>
                    </div>
                </div>
            </div>
            <div className='offersRewardShimmer'>
                <div>
                    <ShimmerCircularImage className={'offersOffersCircleShimmer'} size={50}/>
                </div>
                <div className='offersRewardTextShimmer'>
                    <div>
                        <ShimmerTitle className={'offersOffersTitleShimmer'} line={1}/>
                    </div>
                    <div>
                    <   ShimmerText line={1}/>
                    </div>
                </div>
            </div>
            <div className='offersRewardShimmer'>
                <div>
                    <ShimmerCircularImage className={'offersOffersCircleShimmer'} size={50}/>
                </div>
                <div className='offersRewardTextShimmer'>
                    <div>
                        <ShimmerTitle className={'offersOffersTitleShimmer'} line={1}/>
                    </div>
                    <div>
                    <   ShimmerText line={1}/>
                    </div>
                </div>
            </div>
            <div className='offersRewardShimmer'>
                <div>
                    <ShimmerCircularImage className={'offersOffersCircleShimmer'} size={50}/>
                </div>
                <div className='offersRewardTextShimmer'>
                    <div>
                        <ShimmerTitle className={'offersOffersTitleShimmer'} line={1}/>
                    </div>
                    <div>
                    <   ShimmerText line={1}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default OffersShimmer
