import React from "react";
import { useSelector } from "react-redux";

const FeeReversalLogo = ({ color1, color2 }) => {
  const theme = useSelector((state) => state.theme);
  return (
    <svg
    width="48"
    height="48"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.0835 10.3665V8.92485C2.0835 7.19985 3.49183 5.7915 5.21683 5.7915H14.7835C16.5085 5.7915 17.9168 7.19985 17.9168 8.92485V10.1248H16.2335C15.7668 10.1248 15.3418 10.3082 15.0335 10.6248C14.6835 10.9665 14.4835 11.4582 14.5335 11.9832C14.6085 12.8832 15.4335 13.5415 16.3335 13.5415H17.9168V14.5332C17.9168 16.2582 16.5085 17.6665 14.7835 17.6665H10.2168"
      stroke={color1}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M2.0835 9.67508V5.8668C2.0835 4.87513 2.69183 3.99176 3.61683 3.64176L10.2335 1.14176C11.2668 0.750091 12.3752 1.51678 12.3752 2.62512V5.79177"
      stroke={color1}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19 10.9951V13.0049C19 13.5415 18.6569 13.9805 18.2201 14H16.6916C15.8494 14 15.0773 13.2293 15.0071 12.1756C14.9603 11.561 15.1475 10.9853 15.475 10.5853C15.7636 10.2146 16.1613 10 16.598 10H18.2201C18.6569 10.0195 19 10.4585 19 10.9951Z"
      stroke={color1}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6 9H12"
      stroke={color1}
      stroke-width="1.3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M0.833496 14.75H5.28349C5.81683 14.75 6.25016 15.1833 6.25016 15.7166V16.7833"
      stroke={color1}
      stroke-width="1.3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.85016 13.7334L0.833496 14.75L1.85016 15.7667"
      stroke={color1}
      stroke-width="1.3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.25016 19.15H1.80017C1.26683 19.15 0.833496 18.7167 0.833496 18.1833V17.1167"
      stroke={color1}
      stroke-width="1.3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.23438 20.1671L6.25104 19.1505L5.23438 18.1338"
      stroke={color1}
      stroke-width="1.3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
  );
};

export default FeeReversalLogo;
