import "../styles.css";
import React from "react";
import forward from "../../../assets/images/Forward.svg";
import emptyWallet from "../../../assets/images/emptyWallet.svg";
import { useSelector } from "react-redux";
import { ACCOUNT_STATUS } from "../../../utils/constants";
import { isAccountBlockedOrTransferred } from "../../../utils/functions";

const SummaryWidgetV2 = (props) => {
  const [accounts] = useSelector((state) => [state.accounts]);
  return (
    <div className="mainContainer2">
      <div className="imageWrapper">
        <img src={emptyWallet} />
      </div>
      <div className="summaryWrapper">
        {props.page === "home" && (
          <div className="align2">
            <div className="home-lifetime-earning-text">
              Your {props.name} Card
            </div>
            {!isAccountBlockedOrTransferred(
              accounts?.selectedAccount?.status
            ) && (
              <div>
                <img src={forward} height={"12px"} width={"12px"} alt="" />
              </div>
            )}
          </div>
        )}

        <div className="align" style={{ marginTop: "12px" }}>
          <div className="data-section-container">
            <div style={{ color: "#6D3078" }} className="text-lg">
              {props?.value || "₹0.00"}
            </div>
          </div>
        </div>
        {accounts?.selectedAccount?.status === ACCOUNT_STATUS.BLOCKED && (
          <p className="cardBlockedText">This card is currently blocked</p>
        )}
        {accounts?.selectedAccount?.status === ACCOUNT_STATUS.TRANSFERRED && (
          <p className="cardBlockedText">This card has been Upgraded.</p>
        )}
      </div>
    </div>
  );
};

export default SummaryWidgetV2;
