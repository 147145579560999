import { SET_SESSION, SET_OFFER, SET_MOBILE_NUMBER, SET_GOALS_STATE, RESET_SESSION_STORE, SESSION_LOADING } from "../ActionTypes";

export const setSession = (data) => (dispatch) => {
  dispatch({
    type: SET_SESSION,
    payload: data,
  });
};

export const setCurrentOffer = (data) => (dispatch) => {
  dispatch({
    type: SET_OFFER,
    payload: data,
  });
};

export const setMobileNum = (data) => (dispatch) => {
  dispatch({
    type: SET_MOBILE_NUMBER,
    payload: data
  })
}

export const setGoalsCurrentState = (data) => (dispatch) => {
  dispatch({
    type: SET_GOALS_STATE,
    payload: data
  })
}
export const resetSessionStore = (data) => (dispatch) => {
  dispatch({
    type: RESET_SESSION_STORE,
    payload: data
  })
}

export const setSessionLoading = (data) => (dispatch) => {
  dispatch({
    type: SESSION_LOADING,
    payload: data
  })
}