import React from "react";
import "./styles.css";
import forward from "../../assets/images/Forward.svg";
import cashback from "../../assets/images/cb.svg";
import {
  CashbackLogo,
  FeeReversalLogo,
  FuelSurchargeLogo,
  RewardsLogo,
  VoucherLogo,
} from "../benefitLogo/benefitLogo";
import {
  configurableText,
  formatRewardValue,
  isSessionExpired,
  truncate,
} from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  resetSessionStore,
  setCurrentOffer,
} from "../../store/actions/Session";
import moment from "moment";
import PublicService from "../../services/PublicService";
import timer from "../../assets/images/sandClock.png";
import tick from "../../assets/images/tick.svg";
import Timer from "../../assets/images/Timer";

const OfferCard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const logo = () => {
    return props.data.outcomeType === "VOUCHER" ? (
      <VoucherLogo status={props?.status} />
    ) : props.data.outcomeType === "CASHBACK" ? (
      <CashbackLogo status={props?.status} />
    ) : props.data.outcomeType === "REWARD" ? (
      <RewardsLogo status={props?.status} />
    ) : props.data.outcomeType === "INSTANT_DISCOUNT" ? (
      <CashbackLogo status={props?.status} />
    ) : (
      <FeeReversalLogo status={props?.status} />
    );
  };

  const handleOffer = async () => {
    if (props?.page === "voucher") {
      const response = await PublicService.getBenefit({
        benefitId: props.data.metadata.benefitId,
      });
      if (isSessionExpired(response)) {
        navigate("/sessionExpired");
        dispatch(resetSessionStore());
        return false;
      }
      if (response.status === 200) {
        navigate("/offerDetails");
        dispatch(setCurrentOffer({ currentOffer: response.data.data }));
      } else {
        navigate("/error", {
          state: {
            x_correlation_id: response.headers["x-correlation-id"],
          },
        });
      }
    } else {
      navigate("/offerDetails");
      dispatch(setCurrentOffer({ currentOffer: props.data }));
    }
  };

  const isNote =
    (props.status === "EXPIRED" &&
      Math.abs(
        moment().diff(
          props.data?.subscriptionExpiry || props.data?.benefitExpiry,
          "days"
        )
      ) < 10) ||
    (props.status === "COMPLETED" &&
      Math.abs(
        moment().diff(
          props.data?.subscriptionExpiry || props.data?.benefitExpiry,
          "days"
        )
      ) < 10);

  const showDesc = (props) => {
    if (props.data.benefitType === "MILESTONE") {
      return props.data.currentValue >= props.data.threshold ? (
        <div>
          Milestone achieved
          <img className="achieved-tick-desc" src={tick} />
        </div>
      ) : props.data.threshold > 100 ? (
        formatRewardValue(
          parseFloat(props.data.currentValue || 0),
          "offerCard"
        ) +
        " / " +
        formatRewardValue(parseFloat(props.data.threshold), "offerCard") +
        " milestone reached"
      ) : (
        `${Number(props.data.currentValue)}/${
          props.data.threshold
        } transactions Completed`
      );
    } else {
      return truncate(props.data.description, 36);
    }
  };

  return (
    <div className="main-container">
      <div
        className="sub_offerCard_container"
        style={{ cursor: "pointer" }}
        onClick={() => {
          handleOffer();
        }}
      >
        {props?.page === "voucher" ? (
          <div style={{ marginRight: "16px" }}>
            <img
              src={props.data.merchantLogo}
              height={"56px"}
              width={"56px"}
              alt=""
            />
          </div>
        ) : (
          logo()
        )}
        <div style={{ width: "85%" }}>
          <div className="align">
            <div className="title-offer-card">
              {props.page === "voucher"
                ? truncate(
                    configurableText(props.data.description, props.data),
                    24
                  )
                : truncate(configurableText(props.data.name, props.data), 24)}
              {props.status === "COMPLETED" && (
                <img className="achieved-tick" src={tick} />
              )}
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                handleOffer();
              }}
            >
              <img src={forward} height={"12px"} width={"12px"} alt="" />
            </div>
          </div>
          {props.page === "voucher" ? (
            <div className="voucherOffercardDesc">{`${formatRewardValue(
              parseFloat(props.data.value)
            )} worth voucher earned`}</div>
          ) : (
            <div className="desc">
              {props.data.status === "ACTIVE" && showDesc(props)}
            </div>
          )}
          {props.status === "COMPLETED" ? (
            <div className="offercardAmountText" style={{ color: "#4CAD6D" }}>
              {`${formatRewardValue(
                props.data.benefitType === "MILESTONE"
                  ? props.data.outcomeType === "REWARDS"
                    ? props?.data?.strategy?.value  +" RP"
                    : props?.data?.strategy?.value
                  : props.data.outcomeType === "REWARDS"
                  ? props.data.currentUsageValue +" RP"
                  : props.data.currentUsageValue,
                  props.data.outcomeType === "REWARDS"?"REWARDS":"offerCard"
              )} Earned`}{" "}
            </div>
          ) : props.status === "EXPIRED" ? (
            <div className="offercardAmountText" style={{ color: "#E85757" }}>
              {`${formatRewardValue(
                props.data.benefitType === "MILESTONE"
                  ? props?.data?.strategy?.value
                  : props.data.maxUsageValue,
                props?.data?.outcomeType === "REWARDS" ? "REWARDS" : "offerCard"
              )} ${props?.data?.outcomeType === "REWARDS" ? "RP" : ""} Missed`}{" "}
            </div>
          ) : (
            (props.data.benefitType === "MILESTONE" ||
              props.data.isUsageCountLimitSet ||
              props.data.isUsageValueLimitSet) && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div id="myProgress" style={{ width: "55%" }}>
                  <div
                    id="myBar"
                    style={{
                      backgroundColor:
                        props.data?.currentValue <
                          0.2 * props.data?.threshold &&
                        Math.abs(
                          moment().diff(
                            props.data.subscriptionExpiry ||
                              props.data.benefitExpiry,
                            "days"
                          )
                        ) < 5
                          ? "#e85757"
                          : props.data?.currentValue >= props.data?.threshold
                          ? "#4CAD6D"
                          : "#a6b7e8",
                      width:
                        props.data.benefitType === "MILESTONE"
                          ? `${
                              ((props.data?.currentValue >=
                              props.data?.threshold
                                ? props.data?.threshold
                                : props.data?.currentValue) /
                                (props.data?.threshold || 1)) *
                              100
                            }%`
                          : props.data.isUsageValueLimitSet
                          ? `${
                              ((props.data?.currentUsageValue >=
                              props.data?.maxUsageValue
                                ? props.data?.maxUsageValue
                                : props.data?.currentUsageValue) /
                                (props.data?.maxUsageValue || 1)) *
                              100
                            }%`
                          : `${
                              ((props.data?.currentUsageCount >=
                              props.data?.maxUsageCount
                                ? props.data?.maxUsageCount
                                : props.data?.currentUsageCount) /
                                (props.data?.maxUsageCount || 1)) *
                              100
                            }%`,
                    }}
                  ></div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                  <Timer
                        color1={
                          moment(
                            props.data?.subscriptionExpiry ||
                              props.data?.benefitExpiry
                          ).diff(moment(), "days") <= 5
                            ? "#e85757"
                            : "#636D7D"
                        }
                      />
                  </div>
                  <div
                    className="offerDetailsExpiryText"
                    style={{
                      marginLeft: "4px",
                      color:
                        moment(
                          props.data?.subscriptionExpiry ||
                            props.data?.benefitExpiry
                        ).diff(moment(), "days") <= 5
                          ? "#e85757"
                          : "#636D7D",
                    }}
                  >
                    {moment().diff(
                      props.data?.subscriptionExpiry ||
                        props.data?.benefitExpiry,
                      "days"
                    ) >= 0
                      ? "expires today"
                      : `${Math.abs(
                          moment().diff(
                            props.data?.subscriptionExpiry ||
                              props.data?.benefitExpiry,
                            "days"
                          )
                        )} Days left`}
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
      {isNote && (
        <div>
          <div
            style={{
              padding: "4px",
              backgroundColor: "#F5F7FA",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
            <img src={timer} />
            <div className="note_text">
              in review, transactions update will take 10 days
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OfferCard;
