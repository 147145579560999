import React, { useEffect, useState } from "react";
import left from "../../assets/images/Left.svg";
import sortDown from "../../assets/images/SortDown.svg";
import { useNavigate } from "react-router-dom";
import PublicService from "../../services/PublicService";
import OfferCard from "../../components/offerCard/offerCard";
import { ColorRing } from "react-loader-spinner";
import OffersShimmer from "../../components/shimmer/offers/offersShimmer";
import { useSelector, useDispatch } from "react-redux";
import Select, { components } from "react-select";
import moment, { months } from "moment";
import { setGoalsCurrentState } from "../../store/actions/Session";
import { DATES } from "../../utils/constants";

const Offers = () => {
  const navigate = useNavigate();
  const [session] = useSelector((state) => [state.session]);
  const [selected, setSelected] = useState("ACTIVE");
  const [time, setTime] = useState(
    moment(moment().format("YYYY-MM-DD"))
      .subtract(3, "months")
      .format("YYYY-MM-DD")
  );
  const [loading, setLoading] = useState(true);
  const [activeOffers, setActiveOffers] = useState([]);
  const [completedOffers, setCompletedOffers] = useState([]);
  const [missedOffers, setMissedOffers] = useState([]);

  const dispatch = useDispatch();
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={sortDown} />
      </components.DropdownIndicator>
    );
  };

  const dropDownStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#FAE3D7" : "white",
      color: state.isSelected ? "#000000" : "#000000",
    }),
  };

  const timePeriodOptions = [
    {
      value: moment(moment().format("YYYY-MM-DD"))
        .subtract(3, "months")
        .format("YYYY-MM-DD"),
      label: "3 Months",
    },
    {
      value: moment(moment().format("YYYY-MM-DD"))
        .subtract(6, "months")
        .format("YYYY-MM-DD"),
      label: "6 Months",
    },
    {
      value: moment(moment().format("YYYY-MM-DD"))
        .subtract(12, "months")
        .format("YYYY-MM-DD"),
      label: "12 Months",
    },
    { value: DATES.LIFETIME_BENEFIT_DATE, label: "Lifetime" },
  ];


  useEffect(() => {
    (async () => {
  
      try {
        if (session?.goalsCurrentState) {
          setSelected(session.goalsCurrentState);
        }
        if (session.activeOffers) {
          setActiveOffers(session.activeGoals);
          const tempCompleted = [];
          const tempMissed = [];
          console.log(
            session.completedGoals,
            "_________________________________"
          );
          session.completedGoals.forEach((element) => {
            if (
              element.subscriptionExpiry > time ||
              element.benefitExpiry > time
            ) {
              tempCompleted.push(element);
            }
          });
          session.expiredGoals.forEach((element) => {
            if (
              element.subscriptionExpiry > time ||
              element.benefitExpiry > time
            ) {
              tempMissed.push(element);
            }
          });
          setCompletedOffers(tempCompleted);
          setMissedOffers(tempMissed);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [time, session]);
  return loading ? (
    <OffersShimmer />
  ) : (
    <div className="main">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          maxWidth: "450px",
          backgroundColor: "#FFF",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "16px",
          }}
        >
          <div
            style={{ margin: "0 8px" }}
            onClick={() => {
              dispatch(setGoalsCurrentState({ goalsCurrentState: null }));
              navigate("/");
            }}
          >
            <img src={left} height={"18px"} width={"18px"} alt="" />
          </div>
          <div className="header">Milestones & Exclusive Offers</div>
        </div>
        <div></div>
      </div>
      <div
        style={{
          height: "60px",
          width: "100%",
          maxWidth: "450px",
          marginTop: "12px",
          backgroundColor: "#fff",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "16px 16px 0 16px",
            backgroundColor: "#fff",
          }}
        >
          <div
            onClick={() => {
              setSelected("ACTIVE");
              dispatch(setGoalsCurrentState({ goalsCurrentState: "ACTIVE" }));
              // setTransactions([]);
            }}
            style={{
              padding: "4px 8px",
              width: "45%",
              border:
                selected === "ACTIVE"
                  ? "1px solid #6D3078"
                  : "1px solid #EBEDF0",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Active
          </div>
          <div
            onClick={() => {
              setSelected("COMPLETED");
              dispatch(
                setGoalsCurrentState({ goalsCurrentState: "COMPLETED" })
              );
              // setTransactions
            }}
            style={{
              padding: "4px 8px",
              width: "45%",
              border:
                selected === "COMPLETED"
                  ? "1px solid #6D3078"
                  : "1px solid #EBEDF0",
              borderRadius: "4px",
              marginLeft: "8px",
              cursor: "pointer",
            }}
          >
            Achieved
          </div>
          <div
            onClick={() => {
              setSelected("EXPIRED");
              dispatch(setGoalsCurrentState({ goalsCurrentState: "EXPIRED" }));
              // setTransactions
            }}
            style={{
              padding: "4px 8px",
              width: "45%",
              border:
                selected === "EXPIRED"
                  ? "1px solid #6D3078"
                  : "1px solid #EBEDF0",
              borderRadius: "4px",
              marginLeft: "8px",
              cursor: "pointer",
            }}
          >
            Missed
          </div>
        </div>
        {selected !== "ACTIVE" && (
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "16px",
              backgroundColor: "#FFF",
            }}
          >
            <div
              style={{
                fontWeight: "700",
                color: "#303030",
                margin: "0 12px 4px 12px",
              }}
            >
              History
            </div>
            <div style={{ width: "40%", margin: "0 12px 4px 12px" }}>
              <Select
                className="offers-dropdown"
                styles={dropDownStyles}
                classNamePrefix={"offers-dropdown-child"}
                placeholder="3 Months"
                components={{ DropdownIndicator }}
                isSearchable={false}
                onChange={(e) => {
              
                  setTime(e.value);
                }}
                options={timePeriodOptions}
              />
            </div>
          </div>
        )}
        <div
          style={{
            backgroundColor: "#fff",
            marginTop: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "centers",
            flexDirection: "column",
          }}
        >
          {selected === "ACTIVE" ? (
            activeOffers.map((item) => (
              <div style={{ margin: "7px 0", width: "90%" }}>
                <OfferCard data={item} />
              </div>
            ))
          ) : selected === "COMPLETED" ? (
            completedOffers.map((item) => (
              <div style={{ margin: "7px 0", width: "90%" }}>
                <OfferCard data={item} status="COMPLETED" />
              </div>
            ))
          ) : selected === "EXPIRED" ? (
            missedOffers.map((item) => (
              <div style={{ margin: "7px 0", width: "90%" }}>
                <OfferCard data={item} status="EXPIRED" />
              </div>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Offers;
