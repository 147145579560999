import {
  ADD_ACCOUNT_DETAILS,
  SELECTED_ACCOUNT,
  SET_AGGREGATES,
  TOTAL_LIFETIME_EARNINGS,
} from "../ActionTypes";

export const setAccounts = (data) => (dispatch) => {
  dispatch({
    type: ADD_ACCOUNT_DETAILS,
    payload: data,
  });
};

export const setSelectedAccount = (data) => (dispatch) => {
  dispatch({
    type: SELECTED_ACCOUNT,
    payload: data,
  });
};

export const setTotalLifeTimeEarning = (data) => (dispatch) => {
  dispatch({
    type: TOTAL_LIFETIME_EARNINGS,
    payload: data,
  });
};

export const setAggregates = (data) => (dispatch) => {
  dispatch({
    type: SET_AGGREGATES,
    payload: data,
  });
};
