import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PublicService from "../../services/PublicService";
import { useNavigate } from "react-router-dom";
import left from "../../assets/images/Left.svg";
import qualifyingTransactions from "../../assets/images/qualifyingTransactions.svg";
import moment from "moment";
import { formatRewardValue, isSessionExpired } from "../../utils/functions";
import { BenefitTypes } from "../../utils/constants";
import "./styles.css";
import { resetSessionStore } from "../../store/actions/Session";
import TransactionsShimmer from "../../components/shimmer/transactionsShimmer/transactionsShimmer";

const QualifyingTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [count, setCount] = useState(1);
  const [more, setMore] = useState(true);
  const [session] = useSelector((state) => [state.session]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      if (!session?.currentOffer) {
        navigate("/offers");
        return false;
      }
      const response = await PublicService.getQualifyingTransactions({
        benefitId: session.currentOffer?.benefitId,
        subscriptionId: session.currentOffer?.subscriptionId,
        accountId: localStorage.getItem("accountId"),
        programId: "ccp_HYP4aF9T4G5hVUZZSeS",
        pageNo: count,
      });
      if (isSessionExpired(response)) {
        navigate("/sessionExpired");
        dispatch(resetSessionStore());
        return false;
      }

      if (response.status === 200) {
        setTransactions(response.data.data.content);
        if (count < response.data.data.totalPages) {
          setCount(count + 1);
        } else {
          setMore(false);
        }
      } else {
        navigate("/error", {
          state: {
            x_correlation_id: response.headers["x-correlation-id"],
          },
        });
      }
    })();
  }, []);

  const loadMore = async () => {
    if (more) {
      const response = await PublicService.getQualifyingTransactions({
        benefitId: session.currentOffer.benefitId,
        subscriptionId: session.currentOffer.subscriptionId,
        accountId: localStorage.getItem("accountId"),
        programId: "ccp_HYP4aF9T4G5hVUZZSeS",
        pageNo: count,
      });
      if (isSessionExpired(response)) {
        navigate("/sessionExpired");
        dispatch(resetSessionStore());
        return false;
      }

      if (response.status === 200) {
        setTransactions([...transactions, ...response.data.data.content]);
        if (count < response.data.data.totalPages) {
          setCount(count + 1);
        } else {
          setMore(false);
        }
      } else {
        navigate("/error", {
          state: {
            x_correlation_id: response.headers["x-correlation-id"],
          },
        });
      }
    }
  };
  return (
    <div className="main">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          maxWidth: "450px",
          backgroundColor: "#FFF",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "16px",
          }}
        >
          <div style={{ margin: "0 8px" }} onClick={() => navigate(-1)}>
            <img src={left} height={"18px"} width={"18px"} alt="" />
          </div>
          <div className="header">Qualifying Transactions</div>
        </div>
        <div></div>
      </div>

      {/* {more && <div style={{width:"100%"}} >  <TransactionsShimmer/> </div> } */}
      {transactions.length > 0 && (
        <div
          style={{
            width: "100%",
            backgroundColor: "#fff",
            marginTop: "12px",
            padding: "4px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {transactions.map((item, index) =>
              item && item?.txnNature === "CREDIT"
                ? item?.transactionDate <=
                    session.currentOffer.benefitExpiry && (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        textAlign: "left",
                        padding: "16px",
                        backgroundColor: "#fff",
                        width: "85%",
                        borderBottom: "1px solid #EBEDF0",
                      }}
                    >
                      <div>
                        <div className="text-desc">{item.description}</div>
                        <div
                          className="text-desc"
                          style={{ fontWeight: "500" }}
                        >
                          {moment(item.transactionDate).format("DD MMM 'YY")}
                        </div>
                      </div>
                      <div
                        className="text-desc"
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                          color:
                            item.txnNature === "CREDIT" ? "#4CAD6D" : "#23212C",
                        }}
                      >
                        {item.txnNature === "CREDIT"
                          ? `+ ${formatRewardValue(
                              parseFloat(item.transactionAmount),
                              BenefitTypes.CASHBACK_DISCOUNT.value
                            )}`
                          : `- ${formatRewardValue(
                              parseFloat(item.transactionAmount),
                              BenefitTypes.CASHBACK_DISCOUNT.value
                            )}`}
                      </div>
                    </div>
                  )
                : item &&
                  item?.postingDate <=
                    session.currentOffer.postingLimitDate && (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        textAlign: "left",
                        padding: "16px",
                        backgroundColor: "#fff",
                        width: "85%",
                        borderBottom: "1px solid #EBEDF0",
                      }}
                    >
                      <div>
                        <div className="text-desc">{item.description}</div>
                        <div
                          className="text-desc"
                          style={{ fontWeight: "500" }}
                        >
                          {moment(item.transactionDate).format("DD MMM 'YY")}
                        </div>
                      </div>
                      <div
                        className="text-desc"
                        style={{
                          fontWeight: "500",
                          fontSize: "14px",
                          color:
                            item.txnNature === "CREDIT" ? "#4CAD6D" : "#23212C",
                        }}
                      >
                        {item.txnNature === "CREDIT"
                          ? `+ ${formatRewardValue(
                              parseFloat(item.transactionAmount),
                              BenefitTypes.CASHBACK_DISCOUNT.value
                            )}`
                          : `- ${formatRewardValue(
                              parseFloat(item.transactionAmount),
                              BenefitTypes.CASHBACK_DISCOUNT.value
                            )}`}
                      </div>
                    </div>
                  )
            )}
          </div>

          {more && (
            <div className="offer_details_qualifying_transactions_load_more">
              <div onClick={() => loadMore()}>Load More</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "2px",
                }}
              >
               <img src = {qualifyingTransactions}  />
              </div>
            </div>
          )}
          {!more && transactions.length > 1 && (
            <div className="transactions_end">End Of History</div>
          )}
        </div>
      )}
    </div>
  );
};

export default QualifyingTransactions;
