import React from "react";
import { useSelector } from "react-redux";

const Timer = ({ color1, color2 }) => {
  const theme = useSelector((state) => state.theme);
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.375 6.625C10.375 9.04 8.415 11 6 11C3.585 11 1.625 9.04 1.625 6.625C1.625 4.21 3.585 2.25 6 2.25C8.415 2.25 10.375 4.21 10.375 6.625Z"
        stroke={color1}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 4V6.5"
        stroke={color1}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.5 1H7.5"
        stroke={color1}
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Timer;
