import React from 'react';
import ReactStars from 'react-rating-stars-component'; 
import emptyStar from "../../assets/images/star.svg";
import fullStar from "../../assets/images/filledStar.svg";
import cross from "../../assets/images/close.svg";

const FeedbackComponent = ({
  setFeedbackOpen,
  ratingChanged,
  ratingSelected,
  enteredComment,
  setEnteredComment,
  handleSubmitClick,
}) => {
  return (
    <div className="bottom_sheet_content_container">
      <span onClick={() => setFeedbackOpen(false)} className="bottom_sheet_cross">
        <img src={cross} alt="close icon" />
      </span>
      <div className="rate_exp_question">How would you rate your experience?</div>
      <div className="rate_exp_desc">We are always looking for ways to improve</div>

      <div className="rating_star_component">
        <ReactStars
          count={5}
          key="key"
          onChange={ratingChanged}
          size={45}
          emptyIcon={<img className="unrated_star_icon" src={emptyStar} alt="empty star" />}
          //  isHalf = {false}
          //  halfIcon={<img  style={{"height":"20px"}} src={emptyStar} alt="empty star" />}
          filledIcon={<img className="rated_star_icon" src={fullStar} alt="full star" />}
          activeColor="#ffd700"
        />
      </div>

      {/* {ratingSelected && <div className="rated_great_msg">Great!</div>} */}

      <div className="additional_comments_container">
        <textarea
          className="additional_comments_input"
          placeholder="Any additional comments?"
          type="textarea"
          value={enteredComment}
          onChange={(e) => setEnteredComment(e.target.value)}
        />
      </div>

      <div
        className={
          ratingSelected
            ? "feedback_submit_btn_div fullOpacity"
            : "feedback_submit_btn_div reducedOpcity"
        }
      >
        <button
          disabled={!ratingSelected}
          className="loginSubmitBtn feedback_submit_btn "
          onClick={handleSubmitClick}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default FeedbackComponent;
