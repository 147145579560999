import React from 'react'
import { ShimmerText, ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects'
import './styles.css'

const TransactionsShimmer = () => {
  return (
    <div className='transactionsShimmerContainer'>
      <div className='transactionsItemShimmerContainer'>
        <ShimmerThumbnail className={'transactionsShimmerBlock'}/>
        <ShimmerThumbnail className={'transactionsShimmerBlock'}/>
        <ShimmerThumbnail className={'transactionsShimmerBlock'}/>
        <ShimmerThumbnail className={'transactionsShimmerBlock'}/>
      </div>
    </div>
  )
}

export default TransactionsShimmer
