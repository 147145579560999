import React, { useEffect, useState } from "react";
import left from "../../assets/images/Left.svg";
import "./styles.css";
import SummaryWidget from "../../components/summaryWidget/summaryWidget";
import { useNavigate } from "react-router-dom";
import SummaryCard from "../../components/summaryCard/summaryCard";
import { useDispatch, useSelector } from "react-redux";
import { formatRewardValue } from "../../utils/functions";
import SummaryShimmer from "../../components/shimmer/summary/summaryShimmer";

const Summary = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();

  const [session] = useSelector((state) => [state.session]);
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      try {
        if (session?.summary) {
        
          setData(session.summary);
          setLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [session]);

  return loading ? (
    <SummaryShimmer />
  ) : (
    <div className="main">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          maxWidth: "450px",
          backgroundColor: "#FFF",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "16px",
          }}
        >
          <div style={{ margin: "0 8px" }} onClick={() => navigate("/")}>
            <img src={left} height={"18px"} width={"18px"} alt="" />
          </div>
          <div className="header">Benefits Summary</div>
        </div>
        <div></div>
      </div>

      <div className="card" style={{ marginTop: "12px" }}>
        <SummaryWidget
          value={formatRewardValue(data.benefitTotalValue)}
          page="summary"
        />
      </div>

      <div
        className="card"
        style={{ marginTop: "8px", flexDirection: "column", width: "100%" }}
      >
        {data.aggregates.map(
          (item, index) =>
            ((item.tag === "VOUCHER" && Number(item.currentValue) !== 0) ||
              (item.tag === "FEE_REVERSAL" &&
                Number(item.currentValue) !== 0) ||
              item.tag === "CASHBACK_DISCOUNT" ||
              item.tag === "FUEL_SURCHARGE_WAIVER" ||
              item.tag === "REWARDS" ||
              (!session?.summary?.isLITCustomer && item.tag === "LOUNGE")) && (
              <div style={{ margin: "8px", width: "90%" }}>
                <SummaryCard data={item} />
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default Summary;
