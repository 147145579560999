import axios from "axios";

const Service = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials:true,
  // headers: {
  //   abc: "123",
  // },
  validateStatus: function (status) {
    return true; // Resolve for all status codes
  },
});

// Service.interceptors.request.use(
//   function (req) {
//     const session = store.getState().session

//     if (!publicPaths.includes(req.url)) {
//       req.headers["Authorization"] = `Bearer ${session.deviceToken}`
//     }

//     if (isEncryptedPath(req.url) && req.data) {
//       const key = CryptoJs.enc.Base64.parse(session.encryptionKey)
//       const iv = CryptoJs.enc.Base64.parse(session.encryptionIv)
//       const encryptedRequest = CryptoJs.AES.encrypt(
//         JSON.stringify(req.data),
//         key,
//         { iv },
//       ).toString()
//       req.data = { encryptedRequest }
//     }

//     return req
//   },
//   function (error) {
//     return Promise.reject(error)
//   },
// )

// Service.interceptors.response.use(
//   async function (res) {
//     if (res.status === 401) {
//       store.dispatch(expireSession())
//       return res
//     }

//     const session = store.getState().session
//     const path = res.config.url
//     const encryptedResponse = res.data?.encryptedResponse

//     if (isEncryptedPath(path) && encryptedResponse) {
//       const key = CryptoJs.enc.Base64.parse(session.encryptionKey)
//       const iv = CryptoJs.enc.Base64.parse(session.encryptionIv)
//       const encoder = CryptoJs.enc.Utf8

//       let decryptedResponse = null
//       try {
//         decryptedResponse = CryptoJs.AES.decrypt(encryptedResponse, key, {
//           iv,
//         }).toString(encoder)
//       } catch (error) {
//         consoleError(
//           `An exception occurred while decrypting response for sessionToken ${session.sessionToken} and path ${path}.\nResponse:\n`,
//           res.data,
//           "Error:\n",
//           error,
//         )
//         throw new Error("Failed to decrypt response")
//       }

//       if (!decryptedResponse) {
//         consoleError(
//           `Failed to decrypt response for sessionToken ${session.sessionToken} and path ${path}.\nResponse:\n`,
//           res.data,
//         )
//         throw new Error("Failed to decrypt response")
//       }

//       try {
//         res.data = JSON.parse(decryptedResponse)
//       } catch (error) {
//         // don't log the decrypted response or error here to avoid logging PII data
//         consoleError(
//           `An exception occurred while parsing decrypted response as JSON for sessionToken ${session.sessionToken} and path ${path}.\nResponse:\n`,
//           res.data,
//         )
//         throw new Error("Failed to decrypt response")
//       }
//     }

//     return res
//   },
//   function (error) {
//     return Promise.reject(error)
//   },
// )

export default Service;
