import logo from "./logo.svg";
import "./App.css";
import Routes from "./routes/mainRoutes";
import "./components/shimmer/shimmerGlobalStyles.css";
import queryString from "query-string";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  resetSessionStore,
  setSession,
  setSessionLoading,
} from "./store/actions/Session";
import PublicService from "./services/PublicService";
import { isSessionExpired } from "./utils/functions";
import { Toaster } from "react-hot-toast";
import { setAccounts, setAggregates, setSelectedAccount, setTotalLifeTimeEarning } from "./store/actions/Accounts";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        if (location?.pathname == "/login") {
          return false;
        }
        const queryParams = queryString.parse(location.search);
        queryParams?.accountId &&
          localStorage.setItem("accountId", queryParams?.accountId);

        const resp = await PublicService.fetchSummary();

        const resp2 = await PublicService.getCombinedBenefitsList({
          accountId: localStorage.getItem("accountId"),
          offerType: "ALL",
          outcomeType: "ALL",
          orderBy: "DESC",
          sortBy: "activeTo",
        });

        if (resp.status === 200 && resp2.status === 200) {
          //  setData(resp.data.data);
          const filterSummary = resp.data?.data?.data?.filter((summaryData) => {
            return summaryData.accountId === localStorage.getItem("accountId");
          });

          dispatch(setAggregates(resp.data?.data?.data));
          const account = resp.data?.data?.accounts?.filter((account) => {
            return account.accountId === localStorage.getItem("accountId");
          })
          dispatch(setTotalLifeTimeEarning(resp.data?.data?.lifeTimeEarning));
          dispatch(setAccounts(resp.data?.data?.accounts));
          dispatch(setSelectedAccount(account[0]));

          let merchantActive = [];
          let nonMerchantActive = [];
          for (let i = 0; i < resp2.data?.data?.active.length; i++) {
            if (resp2.data.data.active[i].benefitType !== "INSTANT_DISCOUNT") {
              if (resp2.data.data.active[i].offerType === "MERCHANT_OFFERS") {
                merchantActive.push(resp2.data.data.active[i]);
              } else {
                let temp = resp2.data.data.active[i];
                if (temp.benefitType === "TRANSACTIONAL") {
                  temp.sortComparator = temp.isUsageValueLimitSet
                    ? temp.currentUsageValue / temp.maxUsageValue
                    : temp.currentUsageCount / temp.maxUsageCount;
                } else if (temp.benefitType === "MILESTONE") {
                  temp.sortComparator = temp.currentValue / temp.threshold;
                } else {
                  temp.sortComparator = 2147483647;
                }
                nonMerchantActive.push(temp);
              }
            }
          }

          nonMerchantActive.sort((a, b) => {
            if (a.sortComparator < b.sortComparator) {
              return -1;
            }
            if (a.sortComparator > b.sortComparator) {
              return 1;
            }
            return 0;
          });
          let nonMerchantCompleted = [];
          for (let i = 0; i < resp2.data?.data?.completed.length; i++) {
            if (
              resp2.data.data.completed[i]?.offerType !== "MERCHANT_OFFERS" &&
              resp2.data.data.active[i]?.benefitType !== "INSTANT_DISCOUNT"
            ) {
              nonMerchantCompleted.push(resp2.data.data.completed[i]);
            }
          }
          let nonMerchantExpired = [];
          for (let i = 0; i < resp2.data?.data?.expired.length; i++) {
            if (
              resp2.data.data.expired[i]?.offerType !== "MERCHANT_OFFERS" &&
              resp2.data.data.active[i]?.benefitType !== "INSTANT_DISCOUNT"
            ) {
              nonMerchantExpired.push(resp2.data.data.expired[i]);
            }
          }
          //  setBenefits(nonMerchantActive);
          //  setmerchantBenefits(merchantActive);
          dispatch(
            setSession({
              summary: filterSummary[0],
              activeGoals: nonMerchantActive,
              expiredGoals: nonMerchantExpired,
              completedGoals: nonMerchantCompleted,
              activeOffers: merchantActive,
            })
          );
          dispatch(
            setSessionLoading({
              sessionLoading: false,
            })
          );
        }
        if (isSessionExpired(resp) || isSessionExpired(resp2)) {
          if (location?.pathname == "/") navigate("/login");
          else navigate("/sessionExpired");
          dispatch(resetSessionStore());
          return false;
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return (
    <div className="App">
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{
          bottom: "70px",
        }}
        toastOptions={{
          // Define default options
          className: "",
          duration: 3000,
          style: {
            background: "#EBEDF0",
            color: "#000000",
            fontSize: "12px",
            boxShadow: "none",
            width: "100%",
          },
        }}
      />
      <Routes />
    </div>
  );
}

export default App;
