import React from "react";
import err from "../../assets/images/error.png";
import { useLocation, useNavigate } from "react-router-dom";

const SessionExpired = () => {
  const location = useLocation();
  const navigate = useNavigate()
  return (
    <div className="main">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "450px",
          height: "100vh",
          flexDirection: "column",
        }}
      >
        <div>
          <img src={err} height={"96px"} />
        </div>
        <div
          style={{
            fontSize: "20px",
            fontFamily: "Quicksand",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "28px",
            marginTop: "12px",
            width: "80%",
          }}
          className="disclaimer_error"
        > 
          {location?.state?.text ||
            "Session Expired"}
        </div>

        <div style={{
            fontSize: "14px",
            fontFamily: "Quicksand",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "28px",
            marginTop: "12px",
            width: "80%",
          }}>
        Your session has expired, please login again.
        </div>
      
        <button
        style={{position: "absolute",
          bottom: "20px",
          width: "90%",
          maxWidth: "420px"}}
          className="loginSubmitBtn "
          onClick={()=> {navigate("/login")}}
        >
          Re-login
        </button>
      </div>

  
     
    </div>
  );
};

export default SessionExpired;
