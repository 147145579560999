import React, { useEffect, useState } from "react";
import "./styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import left from "../../assets/images/Left.svg";
import infoIcon from "../../assets/images/info-circle.svg";
import cashback from "../../assets/images/cb.svg";
import PublicService from "../../services/PublicService";
import moment from "moment";
import { BenefitTypes, DATES } from "../../utils/constants";
import Select, { components } from "react-select";
import { formatRewardValue, isSessionExpired, truncate } from "../../utils/functions";
import cb from "../../assets/images/cb.svg";
import TransactionsShimmer from "../../components/shimmer/transactionsShimmer/transactionsShimmer";
import OffersSection from "../../components/offersSection/offersSection";
import { useDispatch, useSelector } from "react-redux";
import sortDown from "../../assets/images/SortDown.svg";
import { resetSessionStore } from "../../store/actions/Session";
import CashbackLogo from "../../assets/images/Cashback";

const Cashback = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [session] = useSelector((state) => [state.session]);
  const [transactions, setTransactions] = useState([]);
  const [change, setChange] = useState(false);
  const [descOpen, setDescOpen] = useState(false);
  const [selected, setSelected] = useState("CASHBACK");
  const [currPage, setCurrPage] = useState(1);
  const [showToTop, setShowToTop] = useState(false);
  const [timeBasedEarnings, setTimeBasedEarnings] = useState(0);
  const [startDate, setStartDate] = useState(
    moment().subtract(3, "months").format("YYYY-MM-DD")
  );
  const [currEndDate, setCurrEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [value, setValue] = useState(3);
  const [loading, setLoading] = useState(true);
  const [benefits, setBenefits] = useState([]);
  const [noOfferSection, setNoOfferSection] = useState(false)
  const dispatch = useDispatch();

  const [timePeriodCount, setTimePeriodCount] = useState(0);
  const [dataFoundOrLifeTime, setDataFoundOrLifeTime] = useState(false) 


  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={sortDown} />
      </components.DropdownIndicator>
    );
  };

  const timePeriodOptions = [
    { value: "3", label: "3 Months" },
    { value: "6", label: "6 Months" },
    { value: "12", label: "12 Months" },
    { value: "60", label: "Lifetime" },
  ];
  useEffect(() => {
    (async () => {
      if(session?.sessionLoading){
        return false;
      }
      try {
        setLoading(true);
        let resp;
        if (state.currentValue > 0) {
          if (selected === "CASHBACK") {
            setLoading(true);
            resp = await PublicService.fetchTransactions({
              tag: "CASHBACK",
              accountId: localStorage.getItem("accountId"),
              id: session.summary.aggregates[0].id.CASHBACK,
              page: currPage,
              startDate: startDate,
              endDate: currEndDate,
            });
          } else {
            resp = await PublicService.fetchTransactions({
              tag: "DISCOUNT",
              accountId: localStorage.getItem("accountId"),
              id: session.summary.aggregates[0].id.DISCOUNT,
              page: currPage,
              startDate: startDate,
              endDate: currEndDate,
            });
          }
          if(isSessionExpired(resp)){
            navigate("/sessionExpired");
            dispatch(
              resetSessionStore()
            );
            return false;
          }

          if (resp.status === 200) {

            if (!dataFoundOrLifeTime) {
              if (value <= 6) {
                if (resp?.data?.data?.count == 0) {
                  setStartDate(
                    moment(moment().format("YYYY-MM-DD"))
                      .subtract(value * 2, "months")
                      .format("YYYY-MM-DD")
                  )
                  setValue(value * 2);
                  setTimePeriodCount(timePeriodCount + 1);
                }
                else {
                  setDataFoundOrLifeTime(true)
                }
              }
              else if(resp?.data?.data?.count == 0){
                setValue(value * 2);
                setTimePeriodCount(timePeriodCount + 1);
                setStartDate(moment(DATES.LIFETIME_BENEFIT_DATE).format("YYYY-MM-DD"));
                setDataFoundOrLifeTime(true)
              }
              else{
                setDataFoundOrLifeTime(true)
              }
            }
            
            setTransactions([...transactions, ...resp.data.data.data]);
            setTimeBasedEarnings(parseFloat(resp.data.data.totalValue));
            if (resp.data.data.hasNextPage) {
              setCurrPage(currPage + 1);
            } else
             setLoading(false);
          }
          else{
            navigate("/error", {
              state: {
                x_correlation_id: resp.headers["x-correlation-id"],
              },
            });
          }

        } else {
          resp = await PublicService.getActiveBenefitsList({
            accountId: localStorage.getItem("accountId"),
            offerType: "ALL",
            outcomeType: "CASHBACK",
            orderBy: "DESC",
            sortBy: "activeTo",
          });
          if(isSessionExpired(resp)){
            navigate("/sessionExpired");
            dispatch(
              resetSessionStore()
            );
            return false;
          }
          if (resp.status === 200) {

            if (!dataFoundOrLifeTime) {
              if (value <= 6) {
                if (resp?.data?.data?.count == 0) {
                  setStartDate(
                    moment(moment().format("YYYY-MM-DD"))
                      .subtract(value * 2, "months")
                      .format("YYYY-MM-DD")
                  )
                  setValue(value * 2);
                  setTimePeriodCount(timePeriodCount + 1);
                }
                else {
                  setDataFoundOrLifeTime(true)
                }
              }
              else if(resp?.data?.data?.count == 0){
                setValue(value * 2);
                setTimePeriodCount(timePeriodCount + 1);
                setStartDate(moment(DATES.LIFETIME_BENEFIT_DATE).format("YYYY-MM-DD"));
                setDataFoundOrLifeTime(true)
              }
              else{
                setDataFoundOrLifeTime(true)
              }
            }


            setBenefits(resp.data.data);
            if(dataFoundOrLifeTime)
              setLoading(false);
          }
          else{
            navigate("/error", {
              state: {
                x_correlation_id: resp.headers["x-correlation-id"],
              },
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [ currPage, change, startDate, session.sessionLoading]);

  useEffect(() => {
    setDataFoundOrLifeTime(false)
    setStartDate (moment().subtract(3, "months").format("YYYY-MM-DD"))
    setCurrPage(1)
    setChange(!change)
    setTimePeriodCount(0)
    setValue(3)
  }, [selected]);

  const back = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const setBacktoTopShow = () => {
    if (window.scrollY > 850) {
      setShowToTop(true);
    } else {
      setShowToTop(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", setBacktoTopShow);
  }, []);

  useEffect(() => {
    setNoOfferSection(
      Object.keys(benefits).length == 0 &&
        (!session.activeOffers || session.activeOffers?.length == 0)
    );
  }, [benefits, session]);

  return (
    <div className="main">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          maxWidth: "450px",
          backgroundColor: "#D7E0FA",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "16px",
          }}
        >
          <div style={{ margin: "0 8px" }} onClick={() => navigate(-1)}>
            <img src={left} height={"18px"} width={"18px"} alt="" />
          </div>
          <div className="header">Cashbacks & Instant Discounts</div>
        </div>
        <div></div>
      </div>

      {state.currentValue > 0 ? (
        <>
          <div
            style={{
              height: "60px",
              width: "100%",
              maxWidth: "450px",
              backgroundColor: "#D7E0FA",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                padding: "20px",
                background: "#ffffff",
                boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.08)",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                width: "80%",
                marginTop: "15%",
              }}
            >
              <div>
               <CashbackLogo color1="#647CBE"/>
              </div>
              <div style={{ marginLeft: "24px", textAlign: "left" }}>
                <div className="desc">Lifetime earnings</div>
                <div className="text-xl">
                  {formatRewardValue(
                    state.currentValue,
                    BenefitTypes.CASHBACK_DISCOUNT.value
                  )}
                </div>
              </div>
            </div>
          </div>

          {showToTop && (
            <div
              style={{
                position: "fixed",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                maxWidth: "450px",
              }}
            >
              <div
                style={{
                  padding: "8px 24px",
                  backgroundColor: "#F37435",
                  borderRadius: "32px",
                  color: "#fff",
                  fontSize: "14px",
                  marginTop: "18px",
                  fontFamily: "Quicksand",
                  fontWeight: "700",
                  lineHeight: "22px",
                  textTransform: "capitalize",
                  cursor: "pointer",
                  boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.16)",
                }}
                onClick={() => back()}
              >
                Back To Top
              </div>
            </div>
          )}
          <div
            style={{
              height: "60px",
              width: "100%",
              maxWidth: "450px",
              marginTop: "20%",
              backgroundColor: "#fff",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "16px 16px 0 16px",
                backgroundColor: "#fff",
              }}
            >
              {
                <div
                  onClick={() => {
                    if (selected != "CASHBACK") {
                      setSelected("CASHBACK");
                      setTransactions([]);
                      setTimeBasedEarnings(null);
                    }
                  }}
                  style={{
                    padding: "4px 8px",
                    width: "45%",
                    border:
                      selected === "CASHBACK"
                        ? "1px solid #6D3078"
                        : "1px solid #EBEDF0",
                    borderRadius: "4px",
                    cursor: "pointer",
                    color: selected === "CASHBACK" ? "#6D3078" :"",
                    backgroundColor:selected == "CASHBACK" ? "#FEFAFF" : "white"
                  }}
                >
                  Cashbacks
                </div>
              }
              {
                <div
                  onClick={() => {
                    if (selected != "DISCOUNTS") {
                      setSelected("DISCOUNTS");
                      setTransactions([]);
                      setTimeBasedEarnings(null);
                    }
                  }}
                  style={{
                    padding: "6px 8px",
                    width: "45%",
                    border:
                      selected === "DISCOUNTS"
                        ? "1px solid #6D3078"
                        : "1px solid #EBEDF0",
                    borderRadius: "4px",
                    marginLeft: "8px",
                    cursor: "pointer",
                    color: selected === "DISCOUNTS" ? "#6D3078" :"",
                    backgroundColor:selected == "DISCOUNTS" ? "#FEFAFF" : "white"
                 
                  }}
                >
                  Instant Discounts
                </div>
              }
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "16px 16px 0 16px", 
                backgroundColor: "#fff",
              }}
            >
              <div
                className="heading-sm"
                style={{ fontWeight: "700", color: "#303030" }}
              >
                History
              </div>
              {dataFoundOrLifeTime && (
                <div className="heading-sm">
                  <Select
                    defaultValue={timePeriodOptions[timePeriodCount]}
                    className="offers-dropdown"
                    classNamePrefix={"offers-dropdown-child"}
                    components={{ DropdownIndicator }}
                    placeholder="3 Months"
                    isSearchable={false}
                    onChange={(e) => {
                      e.value <= 12
                        ? setStartDate(
                            moment(moment().format("YYYY-MM-DD"))
                              .subtract(e.value, "months")
                              .format("YYYY-MM-DD")
                          )
                        : setStartDate(
                            moment(DATES.LIFETIME_BENEFIT_DATE).format("YYYY-MM-DD")
                          );
                      setCurrEndDate(moment().format("YYYY-MM-DD"));
                      setCurrPage(1);
                      setValue(e.value);
                      setTimeBasedEarnings(null);
                      setChange(!change);
                      setTransactions([]);
                    }}
                    options={timePeriodOptions}
                  />
                </div>
              )}
            </div>

            <div>
              {loading ? (
                <div>
                  <TransactionsShimmer />
                </div>
              ) : (
                <>
                  {timeBasedEarnings > 0 && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "16px 16px 0 16px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <div
                        className="heading-sm"
                        style={{
                          fontWeight: "700",
                          fontSize: "12px",
                          color: "#636D7D",
                        }}
                      >
                        {value > 12
                          ? "Lifetime Earnings"
                          : `Past ${value} Months Earnings`}
                      </div>
                      <div className="timeBasedEarnings-amount heading-sm ">
                        {formatRewardValue(timeBasedEarnings)}
                      </div>
                    </div>
                  )}
                  <div className="note-section-div">
                    <hr className="note-section-hr-line"></hr>

                    <div className="note-section">
                      <img className="note-section-info-icon" src={infoIcon} />
                      <span className="note-section-info-text">
                       {selected == "CASHBACK" ? "Transactions updates take upto 48 hours" : "Transactions update on 2nd of each month"}
                      </span>
                    </div>
                  </div>
                  {transactions.length > 0 ? (
                    transactions.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          textAlign: "left",
                          padding: "16px",
                          backgroundColor: "#fff",
                        }}
                      >
                        <div
                          style={{ width: "75%" }}
                          onClick={() => setDescOpen(!descOpen)}
                        >
                          <div className="text-desc">
                            {selected === "DISCOUNTS"
                              ? item?.merchantName
                                ? descOpen
                                  ? `${item?.merchantName}`
                                  : truncate(`${item?.merchantName}`, 40)
                                : descOpen
                                ? item.label
                                : truncate(item.label, 40)
                              : descOpen
                              ? item.description
                              : truncate(item.description, 40)}
                          </div>
                          <div
                            className="text-desc"
                            style={{ fontWeight: "500" }}
                          >
                            {moment(item.postingDate).format("DD MMM 'YY")}
                          </div>
                        </div>
                        <div
                          className="text-desc"
                          style={{
                            fontWeight: "500",
                            fontSize: "14px",
                            color:
                              item.moneyMovementIndicator === "cr"
                                ? "#4CAD6D"
                                : "#23212C",
                            width: "120px",
                            textAlign: "end",
                          }}
                        >
                          {item.moneyMovementIndicator === "cr"
                            ? `+ ${formatRewardValue(
                                parseFloat(item.value),
                                BenefitTypes.CASHBACK_DISCOUNT.value
                              )}`
                            : `- ${formatRewardValue(
                                parseFloat(item.value),
                                BenefitTypes.CASHBACK_DISCOUNT.value
                              )}`}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div style={{ width: "inherit" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          margin: "40% 0",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            padding: "10px",
                            borderRadius: "100%",
                            border: "2px solid #000",
                          }}
                        >
                          <img src={cb} height={"32px"} width={"32px"} />
                        </div>
                        <div
                          style={{
                            marginTop: "8px",
                            fontSize: "20px",
                            fontFamily: "Quicksand",
                            fontWeight: "700",
                            lineHeight: "28px",
                          }}
                        >
                          No Transaction has been done in this window
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
            {transactions.length > 0 && !loading && (
              <div className="transactions_end">End Of History</div>
            )}
          </div>
        </>
      ) : (
        <div style={{ width: "inherit" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              margin: "5%",
              marginTop: noOfferSection ? "40%" : "12%",
            }}
          >
            <div
              style={{
                padding: "10px",
                borderRadius: "100%",
                border: "2px solid #000",
              }}
            >
              <img src={cb} height={"32px"} width={"32px"} />
            </div>
            <div
              style={{
                marginTop: "8px",
                fontSize: "20px",
                fontFamily: "Quicksand",
                fontWeight: "700",
                lineHeight: "28px",
              }}
            >
              {noOfferSection && !loading
                ? "Complete your milestones or avail offers to earn cashbacks  "
                : "No cashbacks earned"}
            </div>

            {noOfferSection && !loading &&
            <div
              style={{
                fontWeight: "400",
                fontSize: "14px",
                color: "#303030",
                marginTop: "20px",
              }}
            >
              Start spending to earn cashbacks
            </div>
            }
          </div>
          <div>
            <OffersSection benefits={benefits} offers={session.activeOffers} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Cashback;
