import React, { useEffect, useState } from "react";
import left from "../../assets/images/Left.svg";
import clock from "../../assets/images/clock.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./styles.css";
import {
  configurableText,
  formatRewardValue,
  isSessionExpired,
  truncate,
} from "../../utils/functions";
import moment from "moment";
import OfferDetailAccordian from "../../components/offerDetailAccordian/offerDetailAccordian";
import {
  CashbackLogo,
  FeeReversalLogo,
  RewardsLogo,
  VoucherLogo,
} from "../../components/benefitLogo/benefitLogo";
import { BenefitTypes } from "../../utils/constants";
import PublicService from "../../services/PublicService";
import OfferDetailsShimmer from "../../components/shimmer/offerDetailsShimmer/offerDetailsShimmer";
import { resetSessionStore } from "../../store/actions/Session";
import Timer from "../../assets/images/Timer";

const OfferDetails = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [cycles, setCycles] = useState([]);
  const [qualTransCount, setQualTranCount] = useState();

  const navigate = useNavigate();
  const [session] = useSelector((state) => [state.session]);
  const dispatch = useDispatch();

  const logo = () => {
    return session.currentOffer.outcomeType === "VOUCHER" ? (
      <VoucherLogo />
    ) : session.currentOffer.outcomeType === "CASHBACK" ? (
      <CashbackLogo />
    ) : session.currentOffer.outcomeType === "REWARD" ? (
      <RewardsLogo />
    ) : session.currentOffer.outcomeType === "INSTANT_DISCOUNT" ? (
      <CashbackLogo />
    ) : (
      <FeeReversalLogo />
    );
  };

  useEffect(() => {
    (async () => {

      if (session.currentOffer?.offerType !== "MERCHANT_OFFERS") {
        if (!session?.currentOffer) {
          navigate("/offers");
        } else {
          const response = await PublicService.getQualifyingTransactions({
            benefitId: session.currentOffer.benefitId,
            subscriptionId: session.currentOffer.subscriptionId,
            accountId: localStorage.getItem("accountId"),
            programId: "ccp_HYP4aF9T4G5hVUZZSeS",
            pageNo: 1,
          });
          if (isSessionExpired(response)) {
            navigate("/sessionExpired");
            dispatch(resetSessionStore());
            return false;
          }
          const response2 = await PublicService.getAllSubscriptions({
            benefitId: session.currentOffer.benefitId,
            accountId: localStorage.getItem("accountId"),
          });

          if (isSessionExpired(response2)) {
            navigate("/sessionExpired");
            dispatch(resetSessionStore());
            return false;
          }
          if (response.status === 200) {
            setTransactions(response?.data?.data?.content.slice(0, 3));
            setQualTranCount(response?.data?.data?.totalElements);
            let tempTxn = [];
            for (let i = 0; i < response?.data?.data?.content.length; i++) {
              if (
                response?.data?.data?.content[i]?.txnNature === "CREDIT" &&
                response?.data?.data?.content[i]?.transactionDate <=
                  session.currentOffer.benefitExpiry
              ) {
                tempTxn.push(response?.data?.data?.content[i]);
              } else if (
                response?.data?.data?.content[i]?.txnNature === "DEBIT" &&
                response?.data?.data?.content[i]?.postingDate <=
                  session.currentOffer.postingLimitDate
              ) {
                tempTxn.push(response?.data?.data?.content[i]);
              }
            }
            setTransactions(tempTxn.slice(0, 3));
          } else {
            navigate("/error", {
              state: {
                x_correlation_id: response.headers["x-correlation-id"],
              },
            });
          }
          if (response2.status === 200) {
            setCycles(response2.data.data);
          } else {
            navigate("/error", {
              state: {
                x_correlation_id: response.headers["x-correlation-id"],
              },
            });
          }
        }
      }

      setLoading(false);
    })();
  }, []);
  return loading ? (
    <div style={{width:"100%"}}>
      <OfferDetailsShimmer/>
    </div>
  ) : (
    <div className="main">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          maxWidth: "450px",
          backgroundColor: "#FFF",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "16px",
          }}
        >
          <div style={{ margin: "0 8px" }} onClick={() => navigate(-1)}>
            <img src={left} height={"18px"} width={"18px"} alt="" />
          </div>
          <div className="header">Offer Details</div>
        </div>
        <div></div>
      </div>
      <div
        style={{
          backgroundColor: "#fff",
          marginTop: "12px",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div style={{ width: "80px", padding: "20px 16px" }}>
          {session.currentOffer.offerType === "MERCHANT_OFFERS" ? (
            <img
              src={session.currentOffer.illustrationUrl}
              height={"63px"}
              width={"63px"}
            />
          ) : (
            <div>{logo()}</div>
          )}{" "}
        </div>
        {session.currentOffer.offerType === "MERCHANT_OFFERS" ? (
          <div style={{ width: "73%", textAlign: "left" }}>
            <div className="headingOfferDetailsName">
              {configurableText(
                session.currentOffer.name,
                session.currentOffer
              )}
            </div>
            <div className="headingOfferDetailsDesc">
              {session.currentOffer.description}
            </div>
          </div>
        ) : (
          <div style={{ width: "73%", textAlign: "left" }}>
            <div className="headingOfferDetailsDesc">
              {configurableText(
                session.currentOffer.name,
                session.currentOffer
              )}
            </div>
            <div className="headingOfferDetailsName">
              {configurableText(
                session.currentOffer.description,
                session.currentOffer
              )}
            </div>
            {(session.currentOffer.benefitType === "MILESTONE" ||
              session.currentOffer.isUsageCountLimitSet ||
              session.currentOffer.isUsageValueLimitSet) && (
              <div
                style={{ width: "100%", display: "flex", alignItems: "center" }}
              >
                <div id="myProgress">
                  <div
                    id="myBar"
                    style={{
                      backgroundColor:
                        session?.currentOffer?.currentValue <
                          0.2 * session?.currentOffer?.threshold &&
                        Math.abs(
                          moment().diff(
                            session?.currentOffer.subscriptionExpiry ||
                              session?.currentOffer.benefitExpiry,
                            "days"
                          )
                        ) < 5
                          ? "#e85757"
                          : session?.currentOffer?.currentValue >=
                            session?.currentOffer?.threshold
                          ? "#4CAD6D"
                          : "#a6b7e8",
                      width:
                        session?.currentOffer.benefitType === "MILESTONE"
                          ? `${
                              ((session?.currentOffer?.currentValue >=
                              session?.currentOffer?.threshold
                                ? session?.currentOffer?.threshold
                                : session?.currentOffer?.currentValue) /
                                (session?.currentOffer?.threshold || 1)) *
                              100
                            }%`
                          : session?.currentOffer.isUsageValueLimitSet
                          ? `${
                              ((session?.currentOffer?.currentUsageValue >=
                              session?.currentOffer?.maxUsageValue
                                ? session?.currentOffer?.maxUsageValue
                                : session?.currentOffer?.currentUsageValue) /
                                (session?.currentOffer?.maxUsageValue || 1)) *
                              100
                            }%`
                          : `${
                              ((session?.currentOffer?.currentUsageCount >=
                              session?.currentOffer?.maxUsageCount
                                ? session?.currentOffer?.maxUsageCount
                                : session?.currentOffer?.currentUsageCount) /
                                (session?.currentOffer?.maxUsageCount || 1)) *
                              100
                            }%`,
                    }}
                  ></div>
                </div>
                {session.currentOffer?.status === "ACTIVE" ? (
                  <div
                    style={{ width: "30%", marginLeft: "4px", display: "flex" }}
                  >
                    <div>
                      <Timer
                        color1={
                          moment(
                            session.currentOffer?.subscriptionExpiry ||
                              session.currentOffer?.benefitExpiry
                          ).diff(moment(), "days") <= 5
                            ? "#e85757"
                            : "#636D7D"
                        }
                      />
                    </div>
                    <div
                      className="offerDetailsExpiryText"
                      style={{
                        marginLeft: "4px",
                        color:
                          moment(
                            session.currentOffer?.subscriptionExpiry ||
                              session.currentOffer?.benefitExpiry
                          ).diff(moment(), "days") <= 5
                            ? "#e85757"
                            : "#636D7D",
                      }}
                    >
                      {moment().diff(
                        session.currentOffer?.subscriptionExpiry ||
                          session.currentOffer?.benefitExpiry,
                        "days"
                      ) >= 0
                        ? "expires today"
                        : `${Math.abs(
                            moment().diff(
                              session.currentOffer?.subscriptionExpiry ||
                                session.currentOffer?.benefitExpiry,
                              "days"
                            )
                          )} Days left`}
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "27%",
                      marginLeft: "4px",
                      display: "flex",
                      alignSelf: "flex-start",
                      justifySelf: "flex-start",
                    }}
                  >
                    <div
                      className="offerDetailsExpiryText"
                      style={{
                        textTransform: "none",
                        fontSize: "12px",
                        marginLeft: "4px",
                        color:
                          session.currentOffer.subscriptionExpiry ===
                          moment().format()
                            ? "#e85757"
                            : "#636D7D",
                      }}
                    >
                      {`Expired on ${moment(
                        session.currentOffer.subscriptionExpiry
                          ? moment(
                              session.currentOffer.subscriptionExpiry
                            ).subtract(1000)
                          : session.currentOffer.benefitExpiry
                      ).format("DD MMM 'YY")}`}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {session.currentOffer.offerType === "MERCHANT_OFFERS" && (
        <div
          style={{
            backgroundColor: "#fff",
            marginTop: "12px",
            width: "100%",
            padding: "4px",
          }}
        >
          {JSON.parse(session.currentOffer.offerDetails)?.voucherCode && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px dashed #D7DBE0",
                padding: "8px 16px",
                borderRadius: "8px",
                margin: "0 24px",
                marginTop: "12px",
                width: "80%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div> Code: </div>
                <div style={{ marginLeft: "8px" }}>
                  {!show
                    ? "*************"
                    : JSON.parse(session.currentOffer.offerDetails)
                        ?.voucherCode}
                </div>
              </div>
              <div
                onClick={() => {
                  !show
                    ? setShow(true)
                    : navigator.clipboard.writeText(
                        JSON.parse(session.currentOffer.offerDetails)
                          ?.voucherCode
                      );
                }}
                className="code_action_text"
              >
                {!show ? "SHOW" : "COPY"}
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "8px 0px",
              borderRadius: "8px",
              width: "80%",
              margin: "0 24px",
            }}
          >
            <div>
              <img src={clock} />{" "}
            </div>
            <div
              className="offerDetailsExpiryMerchantText"
              style={{ marginLeft: "8px" }}
            >{`Expires on ${moment(
              session?.currentOffer?.subscriptionExpiry ||
                session?.currentOffer?.benefitExpiry
            ).format("DD MMM YYYY")}`}</div>
          </div>
        </div>
      )}
      {transactions.length > 0 && (
        <div
          style={{
            width: "100%",
            backgroundColor: "#fff",
            marginTop: "12px",
            padding: "4px 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              width: "90%",
            }}
          >
            <div>
              <div className="offer_details_qualifying_transactions_heading">
                {"Qualifying Transactions"}
              </div>
              <div className="offer_details_qualifying_transactions_sub_heading">
                {"Includes settled transactions only"}
              </div>
            </div>
            <div
              onClick={() => navigate("/qualifyingTransactions")}
              style={{cursor:"pointer"}}
              className="offer_details_qualifying_transactions_view_all"
            >
              View All
            </div>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {transactions.map(
              (item, index) =>
                item && (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      textAlign: "left",
                      padding: "16px",
                      backgroundColor: "#fff",
                      width: "85%",
                      borderBottom: "1px solid #EBEDF0",
                    }}
                  >
                    <div>
                      <div className="text-desc">{item?.description}</div>
                      <div className="text-desc" style={{ fontWeight: "500" }}>
                        {moment(item?.transactionDate).format("DD MMM 'YY")}
                      </div>
                    </div>
                    <div
                      className="text-desc"
                      style={{
                        fontWeight: "500",
                        fontSize: "14px",
                        color:
                          item?.txnNature === "CREDIT" ? "#4CAD6D" : "#23212C",
                      }}
                    >
                      {item?.txnNature === "CREDIT"
                        ? `+ ${formatRewardValue(
                            parseFloat(item?.transactionAmount),
                            BenefitTypes.CASHBACK_DISCOUNT.value
                          )}`
                        : `- ${formatRewardValue(
                            parseFloat(item?.transactionAmount),
                            BenefitTypes.CASHBACK_DISCOUNT.value
                          )}`}
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      )}
      {cycles?.length > 0 &&
        !JSON.parse(session?.currentOffer?.offerDetails)
          ?.isOfferPeriodDisabled && (
          <div
            style={{
              width: "100%",
              backgroundColor: "#fff",
              marginTop: "12px",
              padding: "8px 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "95%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="offer_details_qualifying_transactions_heading">
                  Offer Period
                </div>
                {/* <div className="dateRange">
                  {moment(session?.currentOffer?.benefitActiveFrom).format(
                    "DD MMM 'YY"
                  ) +
                    " - " +
                    moment(session?.currentOffer?.benefitExpiry).format(
                      "DD MMM 'YY"
                    )}
                </div> */}
              </div>
              <div style={{ padding: "12px" }}>
                {cycles.map((item) => (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "12px 0",
                    }}
                  >
                    <div className="cycleDateRange">
                      {moment(item?.activeFrom).format("DD MMM 'YY") +
                        " - " +
                        moment(moment(item?.activeTo).subtract(1000)).format(
                          "DD MMM 'YY"
                        )}
                    </div>
                    <div
                      className="cycleDateRange"
                      style={{
                        fontSize: "11px",
                        color:
                          item.status === "ACTIVE"
                            ? "#E09600"
                            : item.value > 0
                            ? `#4CAD6D`
                            : "#E85757",
                      }}
                    >
                      {item.status === "ACTIVE"
                        ? "In Progress"
                        : item.value > 0
                        ? `Earned ${formatRewardValue(
                            session.currentOffer.outcomeType === "REWARDS"
                              ? item.value + " RP"
                              : item.value,
                            session.currentOffer.outcomeType === "REWARDS"
                              ? "REWARDS"
                              : ""
                          )}`
                        : "Missed"}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      {JSON.parse(session.currentOffer.offerDetails)?.content && (
        <div
          style={{
            width: "100%",
          }}
        >
          {JSON.parse(session.currentOffer.offerDetails)?.content.map(
            (item, index) => (
              <div
                style={{
                  width: "100%",
                  backgroundColor: "#fff",
                  marginTop: "12px",
                  padding: "4px 0",
                }}
                key={index}
              >
                <OfferDetailAccordian data={item} index={index} />
              </div>
            )
          )}
        </div>
      )}

      {JSON.parse(session.currentOffer.offerDetails)?.redirectLink && (
        <div
          style={{
            backgroundColor: "#fff",
            marginTop: "12px",
            width: "100%",
            maxWidth: "450px",
            padding: "12px 0px",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            bottom: "0",
          }}
        >
          <div
            onClick={() =>
              window.open(
                JSON.parse(session.currentOffer.offerDetails).redirectLink
              )
            }
            className="btn_offerdetails"
          >
            Avail Offer
          </div>
        </div>
      )}
    </div>
  );
};

export default OfferDetails;
