import React, { useEffect, useState } from "react";
import PublicService from "../../services/PublicService";
import OfferCard from "../offerCard/offerCard";
import DisplayOfferCard from "../displayOfferCard/displayOfferCard";
import Slider from "react-slick";

const OffersSection = (props) => {
  const [benefits, setBenefits] = useState([]);
  const [merchantBenefits, setMerchantBenefits] = useState([]);
  useEffect(() => {
    (async () => {
      setBenefits(props.benefits);
      setMerchantBenefits(props.offers);
    })();
  }, [benefits]);
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
  };
  return (
    <div className="main">
      {props.benefits[0] && (
        <div
          className="card"
          style={{
            marginTop: "8px",
            flexDirection: "column",
          }}
        >
          <div className="align" style={{ maxWidth: "450px" }}>
            <div className="text-sm-header" style={{ padding: "16px" }}>
              Milestones & Exclusive Offers
            </div>
          </div>
          <div style={{ margin: "12px 0px", width: "90%" }}>
            {props.benefits[0] && (
              <div style={{ marginTop: "12px" }}>
                <OfferCard data={props.benefits[0]} />
              </div>
            )}
            {props.benefits[1] && (
              <div style={{ marginTop: "12px" }}>
                <OfferCard data={props.benefits[1]} />
              </div>
            )}
          </div>
        </div>
      )}

      {merchantBenefits.length > 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow:"hidden"
          }}
        >
          <div
            className="card"
            style={{
              marginTop: "8px",
              flexDirection: "column",
              width: "95%",
              borderRadius: "8px",
              paddingBottom: "20px",
            }}
          >
            <div className="align" style={{ maxWidth: "450px" }}>
              <div className="text-sm-header" style={{ margin: "16px" }}>
                Available Offers
              </div>
              <div
                onClick={() => window.open("https://offers.aubank.in/")}
                className="cta-text-sm"
                style={{ margin: "16px", cursor: "pointer" }}
              >
                View All
              </div>
            </div>
            <div style={{ margin: "4px 0px", width: "95%" }}>
              <div
                style={{
                  // display: "flex",
                  alignItems: "center",
                  marginBottom: "12px",
                }}
              >
                <Slider {...settings}>
                  {merchantBenefits.map((item) => (
                    <div style={{ margin: "4px 7px" }}>
                      <DisplayOfferCard data={item} />
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OffersSection;
