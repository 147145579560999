import React from "react";
import { useSelector } from "react-redux";

const CashbackLogo = ({ color1, color2 }) => {
  const theme = useSelector((state) => state.theme);
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.9165 14.4785V9.14691C2.9165 7.75858 3.76817 6.52186 5.06317 6.03186L14.3265 2.53186C15.7732 1.98353 17.3248 3.05689 17.3248 4.60856V9.04188"
        stroke={color1}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.1665 14H16.3332"
        stroke={color1}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21.0465 15.8086C20.5565 16.287 20.2765 16.9753 20.3465 17.7103C20.4515 18.9703 21.6065 19.892 22.8665 19.892H25.0832V21.2803C25.0832 23.6953 23.1115 25.667 20.6965 25.667H7.30317C4.88817 25.667 2.9165 23.6953 2.9165 21.2803V13.4287C2.9165 11.0137 4.88817 9.04199 7.30317 9.04199H20.6965C23.1115 9.04199 25.0832 11.0137 25.0832 13.4287V15.1087H22.7265C22.0732 15.1087 21.4782 15.3653 21.0465 15.8086Z"
        stroke={color1}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M26.3199 16.2984V18.7018C26.3199 19.3434 25.8066 19.8684 25.1532 19.8917H22.8666C21.6066 19.8917 20.4516 18.97 20.3466 17.71C20.2766 16.975 20.5566 16.2867 21.0466 15.8084C21.4782 15.365 22.0732 15.1084 22.7266 15.1084H25.1532C25.8066 15.1317 26.3199 15.6567 26.3199 16.2984Z"
        stroke={color1}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CashbackLogo;
