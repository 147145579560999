import React from "react";
import "./styles.css";
import forward from "../../assets/images/Forward.svg";
import ranking from "../../assets/images/ranking.svg";

const HomepageSummaryWidget = (props) => {
  return (
    <div className="mainContainerSummery">
      {props.page === "home" && (
        <div className="align">
          {props?.value && props?.value != "₹0" ? (
            <div
              style={{ marginBottom: "-20px" }}
              className="home-lifetime-earning-text"
            >
              Your lifetime earnings
            </div>
          ) : (
            <div
              style={{ marginBottom: "-40px" }}
              className="home-lifetime-earning-text"
            >
              Spend to earn exciting benefits
            </div>
          )}
        </div>
      )}
      <div className="align" style={{ marginTop: "12px" }}>
        <div className="data-section-container">
          {props?.value && props?.value != "₹0" ? (
            <div style={{ color: "#6D3078" }} className="text-lg">
              {props?.value || "0.00"}
            </div>
          ) : (
            <></>
          )}
           <div
            style={{ marginTop: "5px", marginBottom: "-10px" }}
            className="text-sm"
          >
            {props?.value && props?.value != "₹0" ? (
              <></>
            ) : (
              <span>
                You have{" "}
                <span style={{ color: "#6D3078" }}>₹0 lifetime benefits</span>{" "}
                earned.
              </span>
            )}
          </div>
        </div>
        <div>
          <img src={ranking} height={"48px"} width={"48px"} alt="" />
        </div>
      </div>
    </div>
  );
};

export default HomepageSummaryWidget;
