import React, { useState, useEffect } from "react";
import "./style.css";
import { useDispatch } from "react-redux";
import { resetSessionStore, setMobileNum } from "../../store/actions/Session";
import { useLocation, useNavigate } from "react-router-dom";
import PublicService from "../../services/PublicService";
import { isSessionExpired } from "../../utils/functions";
import queryString from "query-string";
import toast, { Toaster } from "react-hot-toast";
import LoginPageBanner from "../../components/svgs/LoginPageBanner";

const Login = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [validationError, setValidationError] = useState();
  const [sendOtp, setSendOtp] = useState('true')
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  var numReg = /^\d+$/;

  const location = useLocation();
  useEffect(() => {
    const listener = event => {
      if (event.code == "Enter" || event.key == "Enter"|| event.code === "NumpadEnter") {
        handleLoginClick();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [mobileNumber]);

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    if(queryParams.sendotp == "false"){
      setSendOtp('false')
    }
  }, []);

  const handleMobileNumChange = (e) => {
    setValidationError("");
    let inputNum = e.target.value;
    if (
      (numReg.test(inputNum) || inputNum == "") &&
      e.target.value.length <= 10
    )
      setMobileNumber(inputNum);
  };

  const handleLoginClick = async (e) => {
    if (mobileNumber.length != 10) {
      setValidationError("Please enter a valid 10-digit mobile number");
      return;
    }
    setIsButtonDisabled(true);
    const response = await PublicService.generateOtp({
      mobileNumber: mobileNumber,
      scope: "LOGIN",
      sendotp: sendOtp == "false" ? false : true
    });
    setIsButtonDisabled(false);
    if (response.status === 200) {
      dispatch(
      setMobileNum({
          mobileNumber: mobileNumber,
          mobileVerificationRefId: response.data.data.mobileVerificationRefId,
          scope: "LOGIN",
        })
      );
      if (response?.data?.data?.reason?.includes("Too many requests")) {
        setValidationError(
          "Received too many OTP generation requests. Try again after sometime"
        );
        return;
      }
      navigate("/verifyOtp", {state: {sendOtp: sendOtp}});
    } else {
      if (response?.data?.errors?.includes("Too many requests")) {
        setValidationError(
          "Received too many OTP generation requests. Try again after sometime"
        );
      } else if (response?.data?.errors?.includes("No records found with mobile")){
        setValidationError("The mobile number you have entered is unregistered.");
      }
      else {
        navigate("/error", {
          state: {
            x_correlation_id: response.headers["x-correlation-id"],
          },
        });
      }
    }
  };

  return (
    <div className="loginPageContainer">
      <div className="">
      <LoginPageBanner/>
        {/* <div className="loginHeadingText">My Benefits</div>
        <div className="loginHeadingDesc">
          Discover your lifetime benefit earnings!
        </div> */}
      </div>
      <div className="loginCredentialsContainer">
        <div className="loginCredentialsHeading">
          {/* <div className="loginCredentialsHeadingText">Welcome!</div> */}
          <div className="loginCredentialsHeadingText">
            Log in to access your benefits!
          </div>
        </div>
        <div className="loginCredentialsInput">
          <div>
            <div className="loginMobileInputs">
              {/* <input
                className="loginMobileCodeInput"
                disabled={true}
                value={"+91"}
                type="text"
              /> */}
              <input
                className="loginMobileNumInput"
                placeholder="Enter your phone number"
                type="number"
                value={mobileNumber}
                onChange={handleMobileNumChange}
              />
            </div>
            <div className="errorMessage">{validationError}</div>
          </div>

          <div className="loginConfirmContainer">
            <button
              disabled={isButtonDisabled}
              className="loginSubmitBtn"
              onClick={handleLoginClick}
            >
              Get OTP
            </button>
            <div className="loginSubmitText">
              By continuing you agree to the&nbsp;
              <a
                onClick={() =>
                  window.open(
                    "https://www.aubank.in/credit-card-member-agreement-pdf-credit-card-member-agreement.pdf"
                  )
                }
                className="loginSubmitTextLinks"
                href=""
              >
                Terms of Use
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
