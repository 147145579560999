import React from "react";
import "./styles.css";
import cashback from "../../assets/images/cb.svg";
import rewards from "../../assets/images/Rewards.svg";
import lounge from "../../assets/images/Lounge.svg";
import feeReversal from "../../assets/images/FeeReversal.svg";
import fuel from "../../assets/images/Fuel.svg";
import voucher from "../../assets/images/Voucher.svg";

export const RewardsLogo = (props) => {
  return (
    <div
      className="icon"
      style={{
        backgroundColor: props?.status === "EXPIRED" ? "#D9D9D9" : "#EDD7FA",
      }}
    >
      <img
        style={{ margin: "0px 2px" }}
        src={rewards}
        height={"28px"}
        width={"28px"}
        alt=""
      />
    </div>
  );
};

export const CashbackLogo = (props) => {
  return (
    <div
      className="icon"
      style={{
        backgroundColor: props?.status === "EXPIRED" ? "#D9D9D9" : "#D7E0FA",
      }}
    >
      <img src={cashback} height={"28px"} width={"28px"} alt="" />
    </div>
  );
};
export const VoucherLogo = (props) => {
  return (
    <div
      className="icon"
      style={{
        backgroundColor: props?.status === "EXPIRED" ? "#D9D9D9" : "#FAE5D7",
      }}
    >
      <img src={voucher} height={"28px"} width={"28px"} alt="" />
    </div>
  );
};
export const FuelSurchargeLogo = (props) => {
  return (
    <div
      className="icon"
      style={{
        backgroundColor: props?.status === "EXPIRED" ? "#D9D9D9" : "#D7D7FA",
      }}
    >
      <img src={fuel} height={"28px"} width={"28px"} alt="" />
    </div>
  );
};

export const FeeReversalLogo = (props) => {
  return (
    <div
      className="icon"
      style={{
        backgroundColor: props?.status === "EXPIRED" ? "#D9D9D9" : "#FADCEB",
      }}
    >
      <img src={feeReversal} height={"28px"} width={"28px"} alt="" />
    </div>
  );
};
