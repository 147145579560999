import { SET_SESSION, SET_OFFER, SET_MOBILE_NUMBER, SET_GOALS_STATE, RESET_SESSION_STORE, SESSION_LOADING } from "../ActionTypes";

const initialState = {
  summary: null,
  currentOffer: null,
  mobileNumber: "",
  mobileVerificationRefId: "",
  activeGoals: null,
  expiredGoals: null,
  completedGoals: null,
  activeOffers: null,
  goalsCurrentState:null,
  sessionLoading:true,
};

const SessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SESSION:
      return {
        ...state,
        summary: action.payload.summary,
        activeGoals: action.payload.activeGoals,
        activeOffers: action.payload.activeOffers,
        expiredGoals: action.payload.expiredGoals,
        completedGoals: action.payload.completedGoals,
      };
    case SET_OFFER:
      return {
        ...state,
        currentOffer: action.payload.currentOffer,
      };

    case SET_MOBILE_NUMBER:
      return {
        ...state,
        mobileNumber: action.payload.mobileNumber,
        mobileVerificationRefId: action.payload.mobileVerificationRefId,
      };

    case SET_GOALS_STATE:{
      return {
        ...state,
        goalsCurrentState: action.payload.goalsCurrentState,
      }
    }
    case RESET_SESSION_STORE:{
      return {
       ...initialState
      }
    }
    case SESSION_LOADING:{
      return {
      ...state,
      sessionLoading:action.payload.sessionLoading
      }
    }
    default:
      return state;
  }
};

export default SessionReducer;
