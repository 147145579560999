import React from 'react'
import { ShimmerBadge, ShimmerButton, ShimmerCircularImage, ShimmerSimpleGallery, ShimmerText, ShimmerThumbnail, ShimmerTitle } from "react-shimmer-effects";
import './styles.css'

const HomeShimmer = () => {
  return (
    <div className='homeShimmerContainer'>
        <div className='benefitsSection'>
            <ShimmerThumbnail className={'homeBenefitsShimmer'}/>
        </div>
        <div className='homeRewardShimmerContainer'>
            <div>
                <ShimmerTitle line={1}/>
            </div>
            <div className='homeRewardShimmer'>
                <div>
                    <ShimmerCircularImage className={'homeOffersCircleShimmer'} size={50}/>
                </div>
                <div className='homeRewardTextShimmer'>
                    <div>
                        <ShimmerTitle className={'homeOffersTitleShimmer'} line={1}/>
                    </div>
                    <div>
                    <   ShimmerText line={1}/>
                    </div>
                </div>
            </div>
            <div className='homeRewardShimmer'>
                <div>
                    <ShimmerCircularImage className={'homeOffersCircleShimmer'} size={50}/>
                </div>
                <div className='homeRewardTextShimmer'>
                    <div>
                        <ShimmerTitle className={'homeOffersTitleShimmer'} line={1}/>
                    </div>
                    <div>
                    <   ShimmerText line={1}/>
                    </div>
                </div>
            </div>
        </div>
        <div className='homeOffersShimmerContainer'>
            <div>
                <ShimmerTitle line={1}/>
            </div>
            <div>
                <ShimmerSimpleGallery card imageHeight={200} caption row={1} col={2}/>
            </div>
        </div>
    </div>
  )
}

export default HomeShimmer
