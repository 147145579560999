import React from "react";
import err from "../../assets/images/error.png";
import { useLocation, useNavigate } from "react-router-dom";

const Error = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="main">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          maxWidth: "450px",
          height: "100vh",
          flexDirection: "column",
        }}
      >
      
      <div style={{backgroundColor:"#FEF2F0", padding:"25px", borderRadius:"50%", }} >
        <div  >
          <img src={err} height={"96px"} />
        </div>
        </div>
        <div
          style={{
            fontSize: "18px",
            fontFamily: "Quicksand",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "28px",
            marginTop: "40px",
            width: "80%",
          }}
          className="disclaimer_error"
        >
          {location?.state?.text ||
            "Sorry something went wrong please try again after sometime \n"}

        </div>
        <div
          style={{
            fontSize: "18px",
            fontFamily: "Quicksand",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "28px",
            marginTop: "40px",
            width: "90%",
            color: "#6D3078"
          }}
          className="disclaimer_error"
        >
          {location?.state?.x_correlation_id && "Error Id - " +
            location?.state?.x_correlation_id}
        </div>

        <button
          style={{
            position: "absolute",
            bottom: "20px",
            width: "90%",
            maxWidth: "420px"
          }}
          className="loginSubmitBtn "
          onClick={() => { navigate("/login") }}
        >
          Go Back
        </button>
      </div>


      
    </div>
  );
};

export default Error;
