import React, { useEffect, useRef, useState } from "react";
import LoginPage from "../../assets/images/LoginPage.svg";
import LoginLoading from "../../assets/images/LoginLoading.svg";
import edit from "../../assets/images/edit.svg";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import PublicService from "../../services/PublicService";
import { resetSessionStore, setMobileNum } from "../../store/actions/Session";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import moment from "moment";
import { isSessionExpired } from "../../utils/functions";
import toast from "react-hot-toast";
import { setAccounts, setSelectedAccount } from "../../store/actions/Accounts";
import { ACCOUNT_STATUS, PROGRAM_ID } from "../../utils/constants";

const intervalTime = 60;
const VerifyOtp = () => {
  const [pin, setPin] = useState(["", "", "", "", "", ""]);
  const mpin0 = useRef(null);
  const mpin1 = useRef(null);
  const mpin2 = useRef(null);
  const mpin3 = useRef(null);
  const mpin4 = useRef(null);
  const mpin5 = useRef(null);
  const interval = useRef(null);
  var numReg = /^\d+$/;
  const [validationError, setValidationError] = useState();
  const [maskedMobileNumber, setMaskedMobileNum] = useState();
  const [otpCount, setOtpCount] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [session] = useSelector((state) => [state.session]);
  const [sendOtp, setSendOtp] = useState("true");
  const [seconds, setSeconds] = useState(intervalTime);
  const [resendLoading, setResendLoading] = useState(false);
  const [resendCount, setResendCount] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const ac = new AbortController();

  useEffect(() => {
    const number = session.mobileNumber;
    if (number == "" || number.length < 10) {
      navigate("/login");
    } else {
      setSeconds(intervalTime);
      interval.current = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    }
    setMaskedMobileNum("xxxxxxx" + number.slice(6));
  }, []);
  const location = useLocation();
  useEffect(() => {
    if (seconds < 1) {
      clearInterval(interval.current);
    }
  }, [seconds]);
  useEffect(() => {
    if (location.state.sendOtp === "false") {
      setSendOtp("false");
    }
  }, []);

  useEffect(() => {
    if (pin.join("").length === 6) {
      handleOTPSubmitClick();
    }
  }, [pin]);

  useEffect(() => {
    if (window.OTPCredential && ac) {
      navigator.credentials
        .get({
          otp: { transport: ["sms"] },
          signal: ac.signal,
        })
        .then((otp) => {
          setPin(otp.code.split(""));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const showResendOtpToast = () =>
    toast(
      <div>
        {" "}
        <span style={{ fontWeight: "600" }}>OTP Resent Successfully!</span>{" "}
        <span style={{ fontWeight: "400" }}> Please check your SMS. </span>{" "}
      </div>
    );

  const handleResend = async () => {
    setResendLoading(true);
    if (resendCount >= 3) {
      navigate("/login");
      return;
    }
    setIsButtonDisabled(true);
    const response = await PublicService.generateOtp({
      mobileNumber: session.mobileNumber,
      scope: "LOGIN",
    });
    setIsButtonDisabled(false);
    if (response.status === 200) {
      showResendOtpToast();
      dispatch(
        setMobileNum({
          mobileNumber: session.mobileNumber,
          mobileVerificationRefId: response.data.data.mobileVerificationRefId,
          scope: "LOGIN",
        })
      );
      setResendLoading(false);
      setResendCount(resendCount + 1);
      setSeconds(intervalTime);
      interval.current = setInterval(() => {
        setSeconds((seconds) => seconds - 1);
      }, 1000);
    } else {
      navigate("/login");
      setResendLoading(false);
    }
  };

  const handleEditClick = () => {
    navigate("/login");
  };

  const handleOTPSubmitClick = async () => {
    for (let i = 0; i < pin.length; i++) {
      if (pin[i] == "") {
        setValidationError("Missing digit : Enter 6 digit OTP");
        return;
      }
    }
    const response = await PublicService.verifyOtp({
      mobileVerificationRefId: session.mobileVerificationRefId,
      mobileNumber: session.mobileNumber,
      scope: "LOGIN",
      otp: pin.join(""),
      sendotp: sendOtp == "false" ? false : true,
    });
    if (isSessionExpired(response)) {
      navigate("/sessionExpired");
      dispatch(resetSessionStore());
      return false;
    }
    if (response.status === 200) {
      if(response.data?.data?.accounts?.length === 0){
        navigate("/unavailableCard");
        return
      }
      if (
        response.data?.data?.accounts.length === 1 &&
        response.data?.data?.accounts[0].status === ACCOUNT_STATUS.BLOCKED
      ) {
        navigate("/error", { state: { text: "Account is blocked!" } });
      } else {
        const accounts = response.data.data?.accounts;
        dispatch(setAccounts(accounts));
        if (accounts.length > 0) {
          if (accounts.length === 1 && accounts[0].status === ACCOUNT_STATUS.BLOCKED) {
            navigate("/error", { state: { text: "Account is blocked!" } });
            return;
          }
          dispatch(setSelectedAccount(accounts[0]));
          localStorage.setItem("accountId", accounts[0].accountId);
        }
        localStorage.setItem("sessionCookie", response.data.data.cookie);
        localStorage.setItem("sessionTime", moment());
        navigate("/");
      }
    } else {
      if (response?.data?.errors.includes("Invalid OTP") && otpCount < 3) {
        setValidationError(response?.data?.errors);
        setOtpCount(otpCount + 1);
      } else if (otpCount == 3) {
        navigate("/error", {
          state: {
            x_correlation_id: response.headers["x-correlation-id"],
            text: "Too many wrong attempts",
          },
        });
      } else {
        navigate("/error", {
          state: {
            x_correlation_id: response.headers["x-correlation-id"],
          },
        });
      }
    }
  };

  return (
    <div className="loginPageContainer">
      <div className="loginPageTopContainer">
        <img className="loginPageImg" src={LoginPage} alt="Loading image..." />
      </div>
      <div className="loginCredentialsContainer">
        <div className="loginCredentialsHeading">
          <div className="loginCredentialsHeadingText">Enter OTP</div>
          <div className="loginCredentialsHeadingDesc">
            Please enter the 6-digit OTP sent to{" "}
            <span className="maskedMobileNum">{maskedMobileNumber}</span>{" "}
            <span>
              <img onClick={handleEditClick} src={edit} alt="edit" />
            </span>
          </div>
        </div>
        <div className="loginCredentialsInput">
          <div>
            <div className="optInputsContainer">
              <input
                className="otpInputs"
                ref={mpin0}
                autocomplete="one-time-code"
                value={pin[0]}
                type="number"
                onChange={(e) => {
                  setValidationError("");
                  let text = e.target.value;
                  if (
                    (text !== "" && !numReg.test(text)) ||
                    Number(text) < 0 ||
                    Number(text) > 9
                  )
                    return false;
                  setPin([text, pin[1], pin[2], pin[3], pin[4], pin[5]]);
                  if (text !== "") {
                    mpin1.current.focus();
                  }
                }}
              />
              <input
                className="otpInputs"
                ref={mpin1}
                value={pin[1]}
                type="number"
                onKeyDown={(e) => {
                  if (e.key === "Backspace" && pin[1] === "") {
                    mpin0.current.focus();
                    setPin(["", "", pin[2], pin[3], pin[4], pin[5]]);
                  }
                }}
                onChange={(e) => {
                  setValidationError("");
                  let text = e.target.value;
                  if (
                    (text !== "" && !numReg.test(text)) ||
                    Number(text) < 0 ||
                    Number(text) > 9
                  )
                    return false;
                  setPin([pin[0], text, pin[2], pin[3], pin[4], pin[5]]);
                  if (text !== "") {
                    mpin2.current.focus();
                  }
                }}
              />
              <input
                className="otpInputs"
                ref={mpin2}
                value={pin[2]}
                type="number"
                onKeyDown={(e) => {
                  if (e.key === "Backspace" && pin[2] === "") {
                    mpin1.current.focus();
                    setPin([pin[0], "", "", pin[3], pin[4], pin[5]]);
                  }
                }}
                onChange={(e) => {
                  setValidationError("");
                  let text = e.target.value;
                  if (
                    (text !== "" && !numReg.test(text)) ||
                    Number(text) < 0 ||
                    Number(text) > 9
                  )
                    return false;
                  setPin([pin[0], pin[1], text, pin[3], pin[4], pin[5]]);
                  if (text !== "") {
                    mpin3.current.focus();
                  }
                }}
              />

              <input
                className="otpInputs"
                ref={mpin3}
                value={pin[3]}
                type="number"
                onKeyDown={(e) => {
                  if (e.key === "Backspace" && pin[3] === "") {
                    mpin2.current.focus();
                    setPin([pin[0], pin[1], "", "", pin[4], pin[5]]);
                  }
                }}
                onChange={(e) => {
                  setValidationError("");
                  let text = e.target.value;
                  if (
                    (text !== "" && !numReg.test(text)) ||
                    Number(text) < 0 ||
                    Number(text) > 9
                  )
                    return false;
                  setPin([pin[0], pin[1], pin[2], text, pin[4], pin[5]]);
                  if (text !== "") {
                    mpin4.current.focus();
                  }
                }}
              />

              <input
                className="otpInputs"
                ref={mpin4}
                value={pin[4]}
                type="number"
                onKeyDown={(e) => {
                  if (e.key === "Backspace" && pin[4] === "") {
                    mpin3.current.focus();
                    setPin([pin[0], pin[1], pin[2], "", "", pin[5]]);
                  }
                }}
                onChange={(e) => {
                  setValidationError("");
                  let text = e.target.value;
                  if (
                    (text !== "" && !numReg.test(text)) ||
                    Number(text) < 0 ||
                    Number(text) > 9
                  )
                    return false;
                  setPin([pin[0], pin[1], pin[2], pin[3], text, pin[5]]);
                  if (text !== "") {
                    mpin5.current.focus();
                  }
                }}
              />

              <input
                className="otpInputs"
                ref={mpin5}
                value={pin[5]}
                type="number"
                onKeyDown={(e) => {
                  if (e.key === "Backspace" && pin[5] === "") {
                    mpin4.current.focus();
                    setPin([pin[0], pin[1], pin[2], pin[3], "", ""]);
                  }
                }}
                onChange={(e) => {
                  setValidationError("");
                  let text = e.target.value;
                  if (
                    (text !== "" && !numReg.test(text)) ||
                    Number(text) < 0 ||
                    Number(text) > 9
                  )
                    return false;
                  setPin([pin[0], pin[1], pin[2], pin[3], pin[4], text]);
                  if (text !== "") {
                    mpin5.current.focus();
                  }
                }}
              />
            </div>
            <div
              className="errorMessage"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              {validationError}
            </div>
          </div>
          <div>
            {seconds >= 1 ? (
              // <div className="loginCredentialsHeadingDesc">Resend OTP in </div>

              <div className="verifyOtp-reqeustNewOtp-text">
                Request new OTP in{" "}
                <span className="verifyOtp-highlight-text">
                  {`00:${seconds >= 10 ? seconds : `0${seconds}`}`}{" "}
                </span>
              </div>
            ) : (
              <>
                <div className="verifyOtp-reqeustNewOtp-text">
                  Didn’t recieve OTP?{" "}
                  <span className="verifyOtp-highlight-text">
                    <button
                      onClick={() => handleResend()}
                      className="verifyOtp-resendOTPButton"
                    >
                      Resend OTP
                    </button>
                  </span>
                </div>
              </>
            )}
            <div mt="32px" px="5" alignItems={"center"}>
              <div>
                <div
                  style={{
                    marginTop: "16px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "160px",
                    height: "160px",
                  }}
                ></div>
              </div>
            </div>
          </div>

          {/* <div className="loginConfirmContainer">
            <button
              disabled={isButtonDisabled}
              className="loginSubmitBtn"
              onClick={() => handleOTPSubmitClick()}
            >
              Confirm OTP
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default VerifyOtp;
