import React from 'react'
import { ShimmerSimpleGallery, ShimmerTitle } from "react-shimmer-effects";
import './styles.css'

const SummaryShimmerV2 = () => {
  return (
    <div className='homeShimmerContainer' style={{width:"100%",marginLeft:"-20px"}}>
        <div className='homeRewardShimmerContainer'>
            <div>
                <ShimmerTitle line={1}/>
            </div>
        </div>
        <div className='homeOffersShimmerContainer'>
            <div>
                <ShimmerTitle line={1}/>
            </div>
            <div>
                <ShimmerSimpleGallery card imageHeight={200} caption row={1} col={2}/>
            </div>
        </div>
    </div>
  )
}

export default SummaryShimmerV2
