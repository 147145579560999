import React from "react";
import { useSelector } from "react-redux";

const RewardsLogo = ({ color1, color2 }) => {
  const theme = useSelector((state) => state.theme);
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0299 8.0542L13.1223 10.3432L15.5778 10.7073L13.7986 12.4969L14.2252 15.0044L12.0299 13.8183L9.83452 15.0044L10.2611 12.4969L8.48193 10.7073L10.9374 10.3432L12.0299 8.0542Z"
        stroke={color1}
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M12.03 22.9534C12.03 22.9534 20.9674 19.9777 20.9674 15.0044C20.9674 12.0287 19.979 12.0287 19.979 9.05302C19.9542 7.3441 20.291 5.64924 20.9674 4.07968L19.979 2.09243H16.0045C14.6269 2.03175 13.2755 1.69566 12.03 1.104C10.7883 1.71366 9.43666 2.06748 8.05548 2.14445H4.08097L3.04053 4.13171C3.73481 5.69736 4.08941 7.39238 4.08097 9.10504C4.08097 12.0807 3.04053 12.0807 3.04053 15.0564C3.09255 19.9777 12.03 22.9534 12.03 22.9534Z"
        stroke={color1}
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export default RewardsLogo;
