import React from "react";
import { useSelector } from "react-redux";

const FuelLogo = ({ color1, color2 }) => {
  const theme = useSelector((state) => state.theme);
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.25016 2.5C4.74027 2.5 3.50016 3.74011 3.50016 5.25V8.25V20.04C3.33136 20.0976 3.1886 20.2134 3.09748 20.3667C3.00637 20.52 2.97285 20.7008 3.00295 20.8766C3.03305 21.0523 3.1248 21.2116 3.26173 21.3259C3.39867 21.4402 3.57183 21.5019 3.75016 21.5H4.12711C4.20763 21.5132 4.28976 21.5132 4.37028 21.5H13.6271C13.7076 21.5132 13.7898 21.5132 13.8703 21.5H14.2502C14.4285 21.5019 14.6017 21.4402 14.7386 21.3259C14.8755 21.2116 14.9673 21.0523 14.9974 20.8766C15.0275 20.7008 14.994 20.52 14.9028 20.3667C14.8117 20.2134 14.669 20.0976 14.5002 20.04V15.5H15.6252H16.7502C16.8977 15.5 17.0002 15.6025 17.0002 15.75V17.5C17.0002 18.8718 18.1283 20 19.5002 20C20.872 20 22.0002 18.8718 22.0002 17.5V10.9834C22.0002 10.2898 21.6791 9.63292 21.131 9.20703L17.2101 6.1582C17.0722 6.04746 16.8996 5.98901 16.7228 5.99316C16.5672 5.99713 16.4168 6.04938 16.2922 6.14268C16.1677 6.23598 16.0752 6.3657 16.0277 6.51388C15.9802 6.66207 15.9799 6.82136 16.0269 6.9697C16.074 7.11804 16.166 7.24808 16.2902 7.3418L18.0002 8.67188V11C18.0002 12.1045 18.8957 13 20.0002 13H20.5002V17.5C20.5002 18.0612 20.0613 18.5 19.5002 18.5C18.939 18.5 18.5002 18.0612 18.5002 17.5V15.75C18.5002 14.7925 17.7076 14 16.7502 14H15.6252C15.6252 14 14.9395 14 14.5002 14C14.5002 11.7545 14.5002 8.25 14.5002 8.25V5.25C14.5002 3.74011 13.26 2.5 11.7502 2.5H6.25016ZM6.25016 4H11.7502C12.4493 4 13.0002 4.55089 13.0002 5.25V8.25C13.0002 8.94911 12.4493 9.5 11.7502 9.5H6.25016C5.55105 9.5 5.00016 8.94911 5.00016 8.25V5.25C5.00016 4.55089 5.55105 4 6.25016 4ZM5.00016 10.6934C5.3763 10.8877 5.80056 11 6.25016 11H11.7502C12.1998 11 12.624 10.8877 13.0002 10.6934V20H5.00016V10.6934Z"
        fill={color1}
      />
    </svg>
  );
};

export default FuelLogo;
