import React from "react";
import Header from "../../components/header/header";
import "./styles.css";

const UnavailableCard = () => {
  return (  <div className="unavailableCardWrapper">
    <Header />
    <div className="unavailableCard">
      <p>This card is currently not available on Discover</p>
    </div>
  </div>)
};

export default UnavailableCard;
