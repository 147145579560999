import React from "react";
import logout from "../../assets/images/Logout.svg";
import { useNavigate } from "react-router-dom";
import PublicService from "../../services/PublicService";
import { resetSessionStore } from "../../store/actions/Session";
import { isSessionExpired } from "../../utils/functions";
import { useDispatch } from "react-redux";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutfun = async () => {
    const response = await PublicService.logout({
      accountId: localStorage.getItem("accountId"),
      sessionToken: localStorage.getItem("sessionCookie"),
    });
    if (isSessionExpired(response)) {
      navigate("/sessionExpired");
      dispatch(resetSessionStore());
      return false;
    }
    if (response.status === 200) {
      dispatch(resetSessionStore());
      localStorage.removeItem("accountId");
      localStorage.removeItem("sessionCookie");
      localStorage.removeItem("sessionTime");

      navigate("/login");
    } else {
      navigate("/error", {
        state: {
          x_correlation_id: response.headers["x-correlation-id"],
        },
      });
    }
  };
  return (
    <div
      style={{
        backgroundColor: "#FEF2F0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <div
        style={{
          padding: "16px",
          color: "#6D3078",
          fontSize: "20px",
          fontFamily: "Quicksand",
          fontStyle: "normal",
          fontWeight: "700",
          lineHeight: "28px",
        }}
      >
        Discover your Credit Card
      </div>
      <div
        style={{ padding: "16px" }}
        onClick={() => {
          logoutfun();
        }}
      >
        <img src={logout} />
      </div>
    </div>
  );
};

export default Header;
