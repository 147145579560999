import React from 'react'
import './styles.css'
import { ShimmerCategoryItem, ShimmerCircularImage, ShimmerText, ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects'

const SummaryShimmer = () => {
  return (
    <div className='summaryShimmerContainer'>
        <ShimmerTitle line={1}/>
        <div className='summaryBenefitsSection'>
            <ShimmerThumbnail className={'summaryBenefitsShimmer'}/>
        </div>
        <div className='summaryRewardShimmerContainer'>
            <div className='summaryRewardShimmer'>
                <div>
                    <ShimmerCircularImage className={'summaryOffersCircleShimmer'} size={50}/>
                </div>
                <div className='summaryRewardTextShimmer'>
                    <div>
                        <ShimmerTitle className={'summaryOffersTitleShimmer'} line={1}/>
                    </div>
                    <div>
                    <   ShimmerText line={1}/>
                    </div>
                </div>
            </div>
            <div className='summaryRewardShimmer'>
                <div>
                    <ShimmerCircularImage className={'summaryOffersCircleShimmer'} size={50}/>
                </div>
                <div className='summaryRewardTextShimmer'>
                    <div>
                        <ShimmerTitle className={'summaryOffersTitleShimmer'} line={1}/>
                    </div>
                    <div>
                    <   ShimmerText line={1}/>
                    </div>
                </div>
            </div>
            <div className='summaryRewardShimmer'>
                <div>
                    <ShimmerCircularImage className={'summaryOffersCircleShimmer'} size={50}/>
                </div>
                <div className='summaryRewardTextShimmer'>
                    <div>
                        <ShimmerTitle className={'summaryOffersTitleShimmer'} line={1}/>
                    </div>
                    <div>
                    <   ShimmerText line={1}/>
                    </div>
                </div>
            </div>
            <div className='summaryRewardShimmer'>
                <div>
                    <ShimmerCircularImage className={'summaryOffersCircleShimmer'} size={50}/>
                </div>
                <div className='summaryRewardTextShimmer'>
                    <div>
                        <ShimmerTitle className={'summaryOffersTitleShimmer'} line={1}/>
                    </div>
                    <div>
                    <   ShimmerText line={1}/>
                    </div>
                </div>
            </div>
            <div className='summaryRewardShimmer'>
                <div>
                    <ShimmerCircularImage className={'summaryOffersCircleShimmer'} size={50}/>
                </div>
                <div className='summaryRewardTextShimmer'>
                    <div>
                        <ShimmerTitle className={'summaryOffersTitleShimmer'} line={1}/>
                    </div>
                    <div>
                    <   ShimmerText line={1}/>
                    </div>
                </div>
            </div>
            <div className='summaryRewardShimmer'>
                <div>
                    <ShimmerCircularImage className={'summaryOffersCircleShimmer'} size={50}/>
                </div>
                <div className='summaryRewardTextShimmer'>
                    <div>
                        <ShimmerTitle className={'summaryOffersTitleShimmer'} line={1}/>
                    </div>
                    <div>
                    <   ShimmerText line={1}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SummaryShimmer
