import React, { useEffect, useState } from "react";
import "./styles.css";
import SummaryWidget from "../../components/summaryWidget/summaryWidget";
import PublicService from "../../services/PublicService";
import OfferCard from "../../components/offerCard/offerCard";
import { useLocation, useNavigate } from "react-router-dom";
import DisplayOfferCard from "../../components/displayOfferCard/displayOfferCard";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import {
  resetSessionStore,
  setSession,
  setSessionLoading,
} from "../../store/actions/Session";
import { ColorRing } from "react-loader-spinner";
import {
  formatRewardValue,
  isAccountBlockedOrTransferred,
  isSessionExpired,
} from "../../utils/functions";
import HomeShimmer from "../../components/shimmer/home/homeShimmer";
import Header from "../../components/header/header";
import right from "../../assets/images/rightChevron.svg";
import close from "../../assets/images/close.svg";
import heroImg from "../../assets/images/LoginLoading.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { BottomSheet } from "react-spring-bottom-sheet";
import FeedbackComponent from "../../components/feedback/feedbackComponent";
import FeedbackSuccess from "../../components/feedback/feedbackSuccess";
import emptyGoals from "../../assets/images/emptyGoal.svg";
import HomepageSummaryWidget from "../../components/homepageSummaryWidget/homepageSummaryWidget";
import {
  setAccounts,
  setAggregates,
  setSelectedAccount,
  setTotalLifeTimeEarning,
} from "../../store/actions/Accounts";
import { ACCOUNT_STATUS, PROGRAM_ID } from "../../utils/constants";
import lock from "../../assets/images/lock.svg";
import SummaryWidgetV2 from "../../components/summaryWidget/summaryV2/summaryWidget";
import SummaryShimmer from "../../components/shimmer/summary/summaryShimmer";
import OffersShimmer from "../../components/shimmer/offers/offersShimmer";
import SummaryShimmerV2 from "../../components/shimmer/summary/SummaryShimmerV2";
import TransferCardSvg from "../../assets/images/TransferCard.svg";

var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
};
const settingsCardCrousel = {
  infinite: false,
  slidesToScroll: 2,
  variableWidth: true,
  accessibility: true,
  focusOnSelect: true,
  arrows: false,
};
const Home = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [benefits, setBenefits] = useState([]);
  const [merchantBenefits, setmerchantBenefits] = useState([]);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [isFeedBackOpen, setIsFeedBackOpen] = useState(false);
  const [isTncOpen, setIsTncOpen] = useState(false);
  const [ratingSelected, setRatingSelected] = useState(false);
  const [enteredRating, setEnteredRating] = useState(0);
  const [enteredComment, setEnteredComment] = useState("");
  const [submitClicked, setSubmitClicked] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeType, setActiveType] = useState();
  const [hasAccountId, setHasAccountId] = useState(true);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);
  const [session, accounts] = useSelector((state) => [
    state.session,
    state.accounts,
  ]);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const ratingChanged = (newRating) => {
    setRatingSelected(true);
    setEnteredRating(newRating);
  };

  useEffect(() => {
    (async () => {
      try {
        const queryParams = queryString.parse(location.search);
        queryParams?.accountId &&
          localStorage.setItem("accountId", queryParams?.accountId);

        // if (!localStorage.getItem("accountId") && !queryParams?.accountId) {
        //   navigate("/error");
        // }

        if (
          session?.summary?.aggregates &&
          session?.activeGoals.length &&
          session?.activeOffers
        ) {
          setData(session.summary);
          setBenefits(session.activeGoals);
          setmerchantBenefits(session.activeOffers);
          if (
            session?.summary?.customer?.tncNotifExpriy >=
            new Date().toISOString()
          ) {
            setIsTncOpen(session?.summary?.customer?.isTncNotifEnabled);
          }

          if (
            session?.summary?.customer?.feedbackcNotifExpriy >=
            new Date().toISOString()
          ) {
            setIsFeedBackOpen(
              session?.summary?.customer?.isfeedbackNotifEnabled
            );
          }

          const indexNumber = accounts?.accounts.findIndex(
            (account) => account.accountId === localStorage.getItem("accountId")
          );
          setInitialSlide(indexNumber);
        } else {
          const resp = await PublicService.fetchSummary();
          if (isSessionExpired(resp)) {
            if (location?.pathname == "/") navigate("/login");
            else navigate("/sessionExpired");
            dispatch(resetSessionStore());
            return false;
          }

          if (resp.status === 200) {
            dispatch(setAccounts(resp.data?.data?.accounts));
            dispatch(setSelectedAccount(resp.data?.data?.accounts[0]));
            const filterSummary = resp.data.data.data.filter((summaryData) => {
              return (
                summaryData.accountId ===
                resp.data?.data?.accounts[0]?.accountId
              );
            });
            dispatch(setAggregates(resp.data?.data?.data));
            dispatch(
              setTotalLifeTimeEarning(resp?.data?.data?.lifeTimeEarning)
            );
            setData(filterSummary[0]);

            if (
              filterSummary[0]?.customer?.tncNotifExpriy >=
              new Date().toISOString()
            ) {
              setIsTncOpen(filterSummary[0]?.customer?.isTncNotifEnabled);
            }

            if (
              filterSummary[0]?.customer?.feedbackcNotifExpriy >=
              new Date().toISOString()
            ) {
              setIsFeedBackOpen(
                filterSummary[0]?.customer?.isfeedbackNotifEnabled
              );
            }

            let resp2;
            if (
              !isAccountBlockedOrTransferred(
                resp.data?.data?.accounts[0]?.status
              ) &&
              localStorage.getItem("accountId")
            ) {
              resp2 = await PublicService.getCombinedBenefitsList({
                accountId: localStorage.getItem("accountId"),
                offerType: "ALL",
                outcomeType: "ALL",
                orderBy: "DESC",
                sortBy: "activeTo",
              });
              if (isSessionExpired(resp2)) {
                if (location?.pathname == "/") navigate("/login");
                else navigate("/sessionExpired");
                dispatch(resetSessionStore());
                return false;
              }
            }

            let merchantActive = [];
            let nonMerchantActive = [];
            let nonMerchantCompleted = [];
            let nonMerchantExpired = [];

            if (resp2?.status === 200) {
              for (let i = 0; i < resp2.data?.data?.active.length; i++) {
                if (
                  resp2?.data?.data?.active[i]?.benefitType !==
                  "INSTANT_DISCOUNT"
                ) {
                  if (
                    resp2?.data?.data?.active[i]?.offerType ===
                    "MERCHANT_OFFERS"
                  ) {
                    merchantActive.push(resp2?.data?.data?.active[i]);
                  } else {
                    let temp = resp2?.data?.data?.active[i];
                    if (temp.benefitType === "TRANSACTIONAL") {
                      temp.sortComparator = temp.isUsageValueLimitSet
                        ? temp.currentUsageValue / temp.maxUsageValue
                        : temp.currentUsageCount / temp.maxUsageCount;
                    } else if (temp.benefitType === "MILESTONE") {
                      temp.sortComparator = temp.currentValue / temp.threshold;
                    } else {
                      temp.sortComparator = 2147483647;
                    }
                    nonMerchantActive.push(temp);
                  }
                }
              }
              nonMerchantActive.sort((a, b) => {
                if (a.sortComparator < b.sortComparator) {
                  return -1;
                }
                if (a.sortComparator > b.sortComparator) {
                  return 1;
                }
                return 0;
              });
              for (let i = 0; i < resp2?.data?.data?.completed.length; i++) {
                if (
                  resp2?.data?.data?.completed[i]?.offerType !==
                    "MERCHANT_OFFERS" &&
                  resp2?.data?.data?.completed[i]?.benefitType !==
                    "INSTANT_DISCOUNT"
                ) {
                  nonMerchantCompleted.push(resp2.data.data.completed[i]);
                }
              }
              for (let i = 0; i < resp2?.data?.data?.expired?.length; i++) {
                if (
                  resp2?.data?.data?.expired[i]?.offerType !==
                    "MERCHANT_OFFERS" &&
                  resp2?.data?.data?.expired[i]?.benefitType !==
                    "INSTANT_DISCOUNT"
                ) {
                  nonMerchantExpired.push(resp2?.data?.data?.expired[i]);
                }
              }
              setBenefits(nonMerchantActive);
              setmerchantBenefits(merchantActive);
            } else if (resp2 === undefined) {
            } else {
              navigate("/error", {
                state: {
                  x_correlation_id: resp2.headers["x-correlation-id"],
                },
              });
            }

            dispatch(
              setSessionLoading({
                setSessionLoading: false,
              })
            );
            dispatch(
              setSession({
                summary: filterSummary[0],
                activeGoals: nonMerchantActive,
                expiredGoals: nonMerchantExpired,
                completedGoals: nonMerchantCompleted,
                activeOffers: merchantActive,
              })
            );
          } else {
            navigate("/error", {
              state: {
                x_correlation_id: resp.headers["x-correlation-id"],
              },
            });
          }
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const getSummary = async () => {
    try {
      setSummaryLoading(true);
      const resp2 = await PublicService.getCombinedBenefitsList({
        accountId: localStorage.getItem("accountId"),
        offerType: "ALL",
        outcomeType: "ALL",
        orderBy: "DESC",
        sortBy: "activeTo",
      });
      if (isSessionExpired(resp2)) {
        if (location?.pathname == "/") navigate("/login");
        else navigate("/sessionExpired");
        dispatch(resetSessionStore());
        return false;
      }
      if (resp2.status === 200) {
        const filterSummary = accounts?.aggregates?.filter((summaryData) => {
          return summaryData.accountId === localStorage.getItem("accountId");
        });
        setData(filterSummary[0]);

        if (
          filterSummary[0]?.customer?.tncNotifExpriy >= new Date().toISOString()
        ) {
          setIsTncOpen(filterSummary[0]?.customer?.isTncNotifEnabled);
        }

        if (
          filterSummary[0]?.customer?.feedbackcNotifExpriy >=
          new Date().toISOString()
        ) {
          setIsFeedBackOpen(filterSummary[0]?.customer?.isfeedbackNotifEnabled);
        }

        let merchantActive = [];
        let nonMerchantActive = [];
        for (let i = 0; i < resp2.data?.data?.active.length; i++) {
          if (
            resp2?.data?.data?.active[i]?.benefitType !== "INSTANT_DISCOUNT"
          ) {
            if (resp2?.data?.data?.active[i]?.offerType === "MERCHANT_OFFERS") {
              merchantActive.push(resp2?.data?.data?.active[i]);
            } else {
              let temp = resp2?.data?.data?.active[i];
              if (temp.benefitType === "TRANSACTIONAL") {
                temp.sortComparator = temp.isUsageValueLimitSet
                  ? temp.currentUsageValue / temp.maxUsageValue
                  : temp.currentUsageCount / temp.maxUsageCount;
              } else if (temp.benefitType === "MILESTONE") {
                temp.sortComparator = temp.currentValue / temp.threshold;
              } else {
                temp.sortComparator = 2147483647;
              }
              nonMerchantActive.push(temp);
            }
          }
        }
        nonMerchantActive.sort((a, b) => {
          if (a.sortComparator < b.sortComparator) {
            return -1;
          }
          if (a.sortComparator > b.sortComparator) {
            return 1;
          }
          return 0;
        });
        let nonMerchantCompleted = [];
        for (let i = 0; i < resp2?.data?.data?.completed.length; i++) {
          if (
            resp2?.data?.data?.completed[i]?.offerType !== "MERCHANT_OFFERS" &&
            resp2?.data?.data?.completed[i]?.benefitType !== "INSTANT_DISCOUNT"
          ) {
            nonMerchantCompleted.push(resp2.data.data.completed[i]);
          }
        }
        let nonMerchantExpired = [];
        for (let i = 0; i < resp2?.data?.data?.expired?.length; i++) {
          if (
            resp2?.data?.data?.expired[i]?.offerType !== "MERCHANT_OFFERS" &&
            resp2?.data?.data?.expired[i]?.benefitType !== "INSTANT_DISCOUNT"
          ) {
            nonMerchantExpired.push(resp2?.data?.data?.expired[i]);
          }
        }
        setBenefits(nonMerchantActive);
        setmerchantBenefits(merchantActive);
        dispatch(
          setSessionLoading({
            setSessionLoading: false,
          })
        );
        dispatch(
          setSession({
            summary: filterSummary[0],
            activeGoals: nonMerchantActive,
            expiredGoals: nonMerchantExpired,
            completedGoals: nonMerchantCompleted,
            activeOffers: merchantActive,
          })
        );
      } else {
        navigate("/error", {
          state: {
            x_correlation_id: resp2.headers["x-correlation-id"],
          },
        });
      }
      setSummaryLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseFeedbackClick = async () => {
    setIsFeedBackOpen(false);
    try {
      const updateResponse = await PublicService.updateCustomerNotif({
        isfeedbackNotifEnabled: false,
      });
      let newSummary = {
        ...session.summary,
        customer: {
          ...session.summary.customer,
          isfeedbackNotifEnabled: false,
        },
      };
      dispatch(
        setSession({
          ...session,
          summary: newSummary,
        })
      );
      if (isSessionExpired(updateResponse)) {
        navigate("/sessionExpired");
        dispatch(resetSessionStore());
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseTncClick = async () => {
    setIsTncOpen(false);
    try {
      const updateResponse = await PublicService.updateCustomerNotif({
        isTncNotifEnabled: false,
      });
      if (isSessionExpired(updateResponse)) {
        navigate("/sessionExpired");
        dispatch(resetSessionStore());
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmitClick = async () => {
    try {
      setEnteredComment("");
      setEnteredRating(0);
      const response = await PublicService.sendFeedback({
        accountId: localStorage.getItem("accountId"),
        rating: enteredRating,
        feedback: enteredComment,
      });
      if (isSessionExpired(response)) {
        navigate("/sessionExpired");
        dispatch(resetSessionStore());
        return false;
      }
      if (response.status === 204) {
        handleCloseFeedbackClick();
        setSubmitClicked(true);
        setTimeout(() => {
          setFeedbackOpen(false);
        }, 3000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleRedirection = async () => {
    try {
      if (session?.summary?.isLITCustomer) {
        let data = {
          accountId: localStorage.getItem("accountId"),
          program: "LIT",
          channel: "IB",
        };
        const res = await PublicService.handshakeRedirection(data);
        window.location.href = res.data?.data?.redirectLink;
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setActiveType(accounts?.selectedAccount?.programName);
  }, [accounts]);

  const handleSelectAccount = (account, index) => {
    dispatch(setSelectedAccount(account));
    setActiveType(account.programName);
    setActiveIndex(index);

    const filterSummary = accounts?.aggregates?.filter((summaryData) => {
      return summaryData.accountId === localStorage.getItem("accountId");
    });
    setData(filterSummary[0]);
    dispatch(
      setSession({
        summary: filterSummary[0],
        activeGoals: null,
        expiredGoals: null,
        completedGoals: null,
        activeOffers: null,
      })
    );
    if (!isAccountBlockedOrTransferred(account?.status)) {
      getSummary();
    }
  };

  const handleNavigateToSummary = () => {
    if (accounts?.selectedAccount?.status) {
      if (!isAccountBlockedOrTransferred(accounts?.selectedAccount?.status)) {
        navigate("/summary");
      }
    } else {
      navigate("/summary");
    }
  };

  return loading &&
    session?.summary?.aggregates &&
    session?.activeGoals.length &&
    session?.activeOffers ? (
    <HomeShimmer />
  ) : loading ? (
    <div className="loader_cont">
      <div className="loaderImg">
        <img src={heroImg} />
      </div>
      <div className="loader_text">
        Adding up all your earnings. Please wait for a moment
      </div>
    </div>
  ) : (
    <div className="main">
      <Header />
      <div className="card" style={{ width: "100%" }}>
        {isFeedBackOpen && (
          <div className="notif_container">
            <div className="internal_notif_container">
              <div className="notif_text">We value your opinion!</div>
              <div className="notif_link" onClick={() => setFeedbackOpen(true)}>
                Share Feedback
              </div>
            </div>
            <div onClick={() => handleCloseFeedbackClick()}>
              <img src={close} />
            </div>
          </div>
        )}
      </div>
      <div className="card" style={{ width: "100%" }}>
        {isTncOpen && (
          <div className="notif_container">
            <div className="internal_notif_container">
              <div className="notif_text">
                We have made changes to our T&Cs.
              </div>
              <div className="notif_link">Learn More</div>
            </div>
            <div onClick={() => handleCloseTncClick()}>
              <img src={close} />
            </div>
          </div>
        )}
      </div>

      {accounts?.accounts.length > 1 && (
        <div className="multicardWrapper">
          <div className="card" style={{ backgroundColor: "#f5f7fa" }}>
            <HomepageSummaryWidget
              value={formatRewardValue(accounts.totalLifeTimeEarning)}
              page="home"
              isFeedback={true}
              isTncNotif={true}
            />
          </div>
          <div className="carouselProviderWrapper">
            <div className="carouselProvider">
              <Slider
                {...settingsCardCrousel}
                accessibility={true}
                focusOnSelect={accounts?.accounts?.length > 2 ? true : false}
                initialSlide={
                  accounts?.accounts?.length > 2 ? initialSlide : false
                }
              >
                {accounts?.accounts?.map((account, index) => (
                  <div key={index} style={{ width: "auto" }}>
                    <div
                      className="homeSliderWrapper"
                      style={
                        account.programName === activeType
                          ? {
                              borderColor: "#6D3078",
                              background: "#FEFAFF",
                              padding: "0px 10px",
                              minWidth: "70px",
                              marginLeft: "2px",
                            }
                          : {
                              color: "#FFFFFF",
                              background: "#FFFFFF",
                              padding: "0px 10px",
                              minWidth: "70px",
                              marginLeft: "2px",
                            }
                      }
                      onClick={() => {
                        handleSelectAccount(account, index);
                      }}
                    >
                      <div className="homeSlider">
                        <p
                          style={
                            account.programName === activeType
                              ? { color: "#6D3078" }
                              : { color: "#303030" }
                          }
                          className="homeSliderText"
                        >
                          {account.programName}
                        </p>
                        {account.status === ACCOUNT_STATUS.BLOCKED && (
                          <div className="svgContainer">
                            <img src={lock} alt="Locked" />
                          </div>
                        )}
                        {account.status === ACCOUNT_STATUS.TRANSFERRED && (
                          <div
                            className="svgContainer"
                            style={{ marginBottom: "0px" }}
                          >
                            <img src={TransferCardSvg} alt="Transfer Card" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div
            style={{ cursor: "pointer" }}
            className="card"
            onClick={handleNavigateToSummary}
          >
            <SummaryWidgetV2
              value={formatRewardValue(data?.benefitTotalValue)}
              name={accounts?.selectedAccount?.programName}
              page="home"
              isFeedback={true}
              isTncNotif={true}
            />
          </div>
        </div>
      )}

      {summaryLoading ? (
        <SummaryShimmerV2 />
      ) : (
        <>
          {accounts?.accounts?.length === 1 && (
            <div
              style={{ cursor: "pointer" }}
              className="card"
              onClick={handleNavigateToSummary}
            >
              <SummaryWidget
                value={formatRewardValue(data?.benefitTotalValue)}
                page="home"
                isFeedback={true}
                isTncNotif={true}
              />
            </div>
          )}

          {/* {session?.summary?.isLITCustomer &&   
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          marginTop: "12px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "95%",
            borderRadius: "4px",
          }}
          className="card"
        >
          <div style={{ margin: "16px", textAlign: "left" }}>
            <div className="segway_title">Manage Benefits</div>
            <div className="segway_desc">
              View/Add more benefits on your card here
            </div>
          </div>
          <div  onClick={()=> handleRedirection()}   style={{ margin: "16px" }}>
            <img src={right} />
          </div>
        </div>
      </div>
    } */}

          {!isAccountBlockedOrTransferred(
            accounts?.selectedAccount?.status
          ) && (
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                className="card"
                style={{
                  marginTop: "8px",
                  flexDirection: "column",
                  width: "95%",
                  borderRadius: " 8px",
                }}
              >
                <div className="align" style={{ maxWidth: "450px" }}>
                  <div className="text-sm-header" style={{ margin: "16px" }}>
                    Milestones & Exclusive Offers
                  </div>
                  <div
                    className="cta-text-sm"
                    style={{ margin: "16px", cursor: "pointer" }}
                    onClick={() => navigate("/Offers")}
                  >
                    View All
                  </div>
                </div>
                {benefits.length > 0 ? (
                  <div style={{ margin: "12px 0px", width: "90%" }}>
                    {benefits[0] && (
                      <div style={{ marginTop: "12px" }}>
                        <OfferCard data={benefits[0]} />
                      </div>
                    )}
                    {benefits[1] && (
                      <div style={{ margin: "12px 0" }}>
                        <OfferCard data={benefits[1]} />
                      </div>
                    )}
                  </div>
                ) : (
                  <div>
                    <div>
                      <img src={emptyGoals} />
                    </div>
                    <div style={{ margin: "12px" }}>
                      No active milesones for you
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {merchantBenefits.length > 0 &&
            !isAccountBlockedOrTransferred(
              accounts?.selectedAccount?.status
            ) && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  className="card"
                  style={{
                    marginTop: "8px",
                    flexDirection: "column",
                    width: "95%",
                    borderRadius: "8px",
                    paddingBottom: "20px",
                  }}
                >
                  <div className="align" style={{ maxWidth: "450px" }}>
                    <div className="text-sm-header" style={{ margin: "16px" }}>
                      Available Offers
                    </div>
                    <div
                      onClick={() => window.open("https://offers.aubank.in/")}
                      className="cta-text-sm"
                      style={{ margin: "16px", cursor: "pointer" }}
                    >
                      View All
                    </div>
                  </div>
                  <div style={{ margin: "4px 0px", width: "95%" }}>
                    <div
                      style={{
                        // display: "flex",
                        alignItems: "center",
                        marginBottom: "12px",
                      }}
                    >
                      <Slider {...settings}>
                        {merchantBenefits.map((item) => (
                          <div style={{ margin: "4px 7px" }}>
                            <DisplayOfferCard data={item} />
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            )}

          <div style={{ width: "100px" }}>
            <BottomSheet style={{ width: "450px" }} open={feedbackOpen}>
              {submitClicked ? (
                <FeedbackSuccess />
              ) : (
                <FeedbackComponent
                  setFeedbackOpen={setFeedbackOpen}
                  ratingChanged={ratingChanged}
                  ratingSelected={ratingSelected}
                  enteredComment={enteredComment}
                  setEnteredComment={setEnteredComment}
                  handleSubmitClick={handleSubmitClick}
                />
              )}
            </BottomSheet>
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
