import moment from "moment";
import { ACCOUNT_STATUS } from "./constants";

export const formatRewardValue = (value, type) => {
  return type === "REWARDS"
    ? value?.toLocaleString("en-IN")
    : value?.toLocaleString("en-IN", {
        style: "currency",
        currency: "INR",
        maximumFractionDigits: 0, // removing decimal points from everywhere
      });
};

export const truncate = (string, length) => {
  return string?.length > length ? string?.slice(0, length) + "..." : string;
};

export const configurableText = (text, data) => {
  let tempText = text.split("#");
  let newText = "";
  for (let i = 0; i < tempText.length; i++) {
    if (tempText[i][0] === "$") {
      let temp = tempText[i].split("$");
      temp = temp[1].split(".");
      let newVal = data;
      for (let j = 0; j < temp.length; j++) {
        newVal = newVal[temp[j]];
      }
      newText = newText + newVal;

      // newText = newText + da
    } else {
      newText = newText + tempText[i];
    }
  }

  return newText;
};

export const serviceFunction = (data) => {
  if (true) {
    return { ...data, sessionToken: localStorage.getItem("sessionCookie") };
  } else {
    localStorage.removeItem("sessionCookie");
    return data;
  }
};

export const isSessionExpired = (res) => {
  if (res?.status === 500) {
    if (res?.data?.message === "Session Expired") {
      return true;
    }
  }
  return false;
};

export const isAccountBlockedOrTransferred = (status) => {
  if (
    status === ACCOUNT_STATUS.BLOCKED ||
    status === ACCOUNT_STATUS.TRANSFERRED
  ) {
    return true;
  } else {
    return false;
  }
};
