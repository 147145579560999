import React from "react";
import "./styles.css";
import forward from "../../assets/images/Forward.svg";
import { BenefitTypes, offerTypes } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { formatRewardValue } from "../../utils/functions";

const SummaryCard = (props) => {
  const navigate = useNavigate();
  return (
    <div
      className="main-container-summary-card"
      style={{ cursor: "pointer" }}
      onClick={() => {
        window.scrollTo(0, 0);
        navigate(BenefitTypes[props.data.tag].redirect, {
          state: props.data,
        });
      }}
    >
      <div
        className="icon"
        style={{ backgroundColor: BenefitTypes[props.data.tag].iconColor }}
      >
        <img
          src={BenefitTypes[props.data.tag].icon}
          height={"30px"}
          width={"30px"}
          alt=""
        />
      </div>
      <div style={{ width: "85%" }}>
        <div className="align">
          <div className="titlesummarycard">
            {BenefitTypes[props.data.tag].name}
          </div>
          <div style={{ marginRight: "12px" }}>
            <img src={forward} height={"12px"} width={"12px"} alt="" />
          </div>
        </div>
        <div className="desc">
          {props.data.tag === "REWARDS"
            ? `${formatRewardValue(props.data.currentValue, "REWARDS")} RP`
            : formatRewardValue(parseFloat(props.data.currentValue))}
        </div>
      </div>
    </div>
  );
};

export default SummaryCard;
