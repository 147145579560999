import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import RootReducer from "./reducers/index";

const composeEnhancers =
  process.env.REACT_APP_ENV === "dev" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const middleware = [thunk];
const initialState = {};

const store = createStore(
  RootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
