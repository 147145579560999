import React from 'react';
import feedbackSuccessGif from "../../assets/images/feedbackSuccess.gif";

const FeedbackSuccess = () => {
  return (
    <div>
      <div className="feedback_success_gif_div">
        <img className="feebback_success_gif" src={feedbackSuccessGif} alt="feedback success gif" />
      </div>

      <div className="feebback_success_msg">Thank you for the feedback!</div>
      <div className="feebback_success_msg_desc">This will help us improve your experience</div>
    </div>
  );
};

export default FeedbackSuccess;
