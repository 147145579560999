import React from 'react'
import './styles.css'
import { ShimmerThumbnail, ShimmerTitle } from 'react-shimmer-effects'

const OfferDetailsShimmer = () => {
  return (
    <div className='offerDetailsShimmerContainer'>
        <ShimmerTitle className={'offerDetailsMainTitleShimmer'} line={1}/>
      <div>
        <ShimmerThumbnail className={'offerDetailsShimmerBlock'}/>
        <ShimmerThumbnail className={'offerDetailsShimmerBlock2'}/>
        <ShimmerThumbnail className={'offerDetailsShimmerBlock2'}/>

      </div>
    </div>
  )
}

export default OfferDetailsShimmer
