import React, { useState } from "react";
import "./styles.css";
import up from "../../assets/images/up.svg";
import down from "../../assets/images/down.svg";

const OfferDetailAccordian = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px",
        }}
      >
        <div className="accordian_text_lg">{props.data.title}</div>
        <div onClick={() => setOpen(!open)}>
          {!open ? <img src={down} /> : <img src={up} />}
        </div>
      </div>
      {open && (
        <div>
          {props.data.points.map((item) => (
            <div
              className="accordian_text_sm"
              style={{  padding: "2px 16px", textAlign: "left" }}
            >{`• ${item}`}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default OfferDetailAccordian;
