import React, { useEffect, useState } from "react";
import "./styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import left from "../../assets/images/Left.svg";
import cashback from "../../assets/images/Lounge.svg";
import rightIcon from "../../assets/images/rightArrowDark.svg";
import noteStar from "../../assets/images/noteStar.svg";
import PublicService from "../../services/PublicService";
import moment from "moment";
import {
  BenefitTypes,
  DATES,
  LOUNGE_LIST_URL,
} from "../../utils/constants";
import Select, { components } from "react-select";
import sortDown from "../../assets/images/SortDown.svg";
import {
  formatRewardValue,
  isSessionExpired,
  truncate,
} from "../../utils/functions";
import cb from "../../assets/images/Lounge.svg";
import TransactionsShimmer from "../../components/shimmer/transactionsShimmer/transactionsShimmer";
import OffersSection from "../../components/offersSection/offersSection";
import { useDispatch, useSelector } from "react-redux";
import { resetSessionStore } from "../../store/actions/Session";
import infoIcon from "../../assets/images/info-circle.svg";
import LoungeLogo from "../../assets/images/Lounge";
import giftBoxLogo from "../../assets/images/giftBoxLogo.svg";

const tempParams = {
  currentValue: 44,
  isDomestic: true,
  isPriority: true,
  isRailway: true,
};

const Lounge = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const session = useSelector((state) => state.session);
  const accounts = useSelector((state) => state.accounts);
  const selectedProgram = accounts?.selectedAccount;
  const [transactions, setTransactions] = useState([]);
  const [change, setChange] = useState(false);
  const [descOpen, setDescOpen] = useState(false);
  const [viewHistoryEnabled, setViewHistoryEnabled] = useState(false);
  const [selected, setSelected] = useState(
    state?.metadata?.LOUNGE_DOMESTIC?.price
      ? "DOM"
      : state?.metadata?.LOUNGE_RAILWAY?.price
      ? "RAILWAY"
      : "PRIORITY"
  );
  const [currPage, setCurrPage] = useState(1);
  const [showToTop, setShowToTop] = useState(false);
  const [timeBasedEarnings, setTimeBasedEarnings] = useState(0);
  const [startDate, setStartDate] = useState(
    moment().subtract(3, "months").format("YYYY-MM-DD")
  );
  const [currEndDate, setCurrEndDate] = useState(moment().format("YYYY-MM-DD"));
  const [value, setValue] = useState(3);
  const [loading, setLoading] = useState(true);
  const [benefits, setBenefits] = useState([]);
  const [timePeriodCount, setTimePeriodCount] = useState(0);
  const [dataFoundOrLifeTime, setDataFoundOrLifeTime] = useState(false);

  const dispatch = useDispatch();
  const isOnlyOneLounge = Object.keys(state.metadata).length === 1;

  const timePeriodOptions = [
    { value: "3", label: "3 Months" },
    { value: "6", label: "6 Months" },
    { value: "12", label: "12 Months" },
    { value: "60", label: "Lifetime" },
  ];

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={sortDown} />
      </components.DropdownIndicator>
    );
  };

  const getQuarterRange = () => {
    const start = moment().startOf("quarter").format("MMM ‘YY");
    const end = moment().endOf("quarter").format("MMM ‘YY");
    return start + " - " + end;
  };

  useEffect(() => {
    (async () => {
      if (session?.sessionLoading) {
        return false;
      }
      try {
        let resp;
        if (state.currentValue > 0) {
          if (selected === "DOM") {
            setLoading(true);
            resp = await PublicService.fetchTransactions({
              tag: "LOUNGE_DOMESTIC",
              accountId: localStorage.getItem("accountId"),
              id: localStorage.getItem("accountId"),
              page: currPage,
              startDate: startDate,
              endDate: currEndDate,
            });
          } else if (selected === "PRIORITY") {
            resp = await PublicService.fetchTransactions({
              tag: "LOUNGE_INTERNATIONAL",
              accountId: localStorage.getItem("accountId"),
              id: localStorage.getItem("accountId"),
              page: currPage,
              startDate: startDate,
              endDate: currEndDate,
            });
          } else {
            resp = await PublicService.fetchTransactions({
              tag: "LOUNGE_RAILWAY",
              accountId: localStorage.getItem("accountId"),
              id: localStorage.getItem("accountId"),
              page: currPage,
              startDate: startDate,
              endDate: currEndDate,
            });
          }
          if (isSessionExpired(resp)) {
            navigate("/sessionExpired");
            dispatch(resetSessionStore());
            return false;
          }

          if (resp.status === 200) {
            if (!dataFoundOrLifeTime) {
              if (value <= 6) {
                if (resp?.data?.data?.count == 0) {
                  setStartDate(
                    moment(moment().format("YYYY-MM-DD"))
                      .subtract(value * 2, "months")
                      .format("YYYY-MM-DD")
                  );
                  setValue(value * 2);
                  setTimePeriodCount(timePeriodCount + 1);
                  setChange(!change);
                } else {
                  setDataFoundOrLifeTime(true);
                }
              } else if (resp?.data?.data?.count == 0) {
                setValue(value * 2);
                setTimePeriodCount(timePeriodCount + 1);
                setStartDate(
                  moment(DATES.LIFETIME_BENEFIT_DATE).format("YYYY-MM-DD")
                );
                setDataFoundOrLifeTime(true);
                setChange(!change);
              } else {
                setDataFoundOrLifeTime(true);
              }
            }

            setTransactions([...transactions, ...resp.data.data.data]);
            setTimeBasedEarnings(parseFloat(resp.data.data.totalValue));
            if (resp.data.data.hasNextPage) {
              setCurrPage(currPage + 1);
            } else setLoading(false);
          } else {
            navigate("/error", {
              state: {
                x_correlation_id: resp.headers["x-correlation-id"],
              },
            });
          }
        } else {
          resp = await PublicService.getActiveBenefitsList({
            accountId: localStorage.getItem("accountId"),
            offerType: "ALL",
            outcomeType: "CASHBACK",
            orderBy: "DESC",
            sortBy: "activeTo",
          });
          if (isSessionExpired(resp)) {
            navigate("/sessionExpired");
            dispatch(resetSessionStore());
            return false;
          }

          if (resp.status === 200) {
            if (!dataFoundOrLifeTime) {
              if (value <= 6) {
                if (resp?.data?.data?.count == 0) {
                  setStartDate(
                    moment(moment().format("YYYY-MM-DD"))
                      .subtract(value * 2, "months")
                      .format("YYYY-MM-DD")
                  );
                  setValue(value * 2);
                  setTimePeriodCount(timePeriodCount + 1);
                  setChange(!change);
                } else {
                  setDataFoundOrLifeTime(true);
                }
              } else if (resp?.data?.data?.count == 0) {
                setValue(value * 2);
                setTimePeriodCount(timePeriodCount + 1);
                setStartDate(
                  moment(DATES.LIFETIME_BENEFIT_DATE).format("YYYY-MM-DD")
                );
                setDataFoundOrLifeTime(true);
                setChange(!change);
              } else {
                setDataFoundOrLifeTime(true);
              }
            }

            setBenefits(resp.data.data);
            setLoading(false);
          } else {
            navigate("/error", {
              state: {
                x_correlation_id: resp.headers["x-correlation-id"],
              },
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [change, currPage, session.sessionLoading]);

  useEffect(() => {
    setDataFoundOrLifeTime(false);
    setStartDate(moment().subtract(3, "months").format("YYYY-MM-DD"));
    setTimePeriodCount(0);
    setValue(3);
    setCurrPage(1);
    setTimeout(() => {
      setChange(!change);
    }, 1);
  }, [selected]);

  const back = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  const setBacktoTopShow = () => {
    if (window.scrollY > 850) {
      setShowToTop(true);
    } else {
      setShowToTop(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", setBacktoTopShow);
  }, []);

  const transactionsSectionContent = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "16px 16px 0 16px",
            backgroundColor: "#fff",
          }}
        >
          <div
            className="heading-sm"
            style={{ fontWeight: "700", color: "#303030" }}
          >
            Visit History
          </div>
          {dataFoundOrLifeTime && (
            <div className="heading-sm">
              <Select
                className="offers-dropdown"
                classNamePrefix={"offers-dropdown-child"}
                defaultValue={timePeriodOptions[timePeriodCount]}
                components={{ DropdownIndicator }}
                placeholder="3 Months"
                isSearchable={false}
                onChange={(e) => {
                  e.value <= 12
                    ? setStartDate(
                        moment(moment().format("YYYY-MM-DD"))
                          .subtract(e.value, "months")
                          .format("YYYY-MM-DD")
                      )
                    : setStartDate(
                        moment(DATES.LIFETIME_BENEFIT_DATE).format("YYYY-MM-DD")
                      );
                  setCurrEndDate(moment().format("YYYY-MM-DD"));
                  if (currPage == 1) {
                    setChange(!change);
                  } else {
                    setCurrPage(1);
                  }
                  setValue(e.value);
                  setTimeBasedEarnings(null);
                  setLoading(true);
                  setTransactions([]);
                }}
                options={timePeriodOptions}
              />
            </div>
          )}
        </div>

        <div>
          {loading ? (
            <div>
              <TransactionsShimmer />
            </div>
          ) : (
            <>
              {timeBasedEarnings > 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "16px 16px 0 16px",
                    backgroundColor: "#fff",
                  }}
                >
                  <div
                    className="heading-sm"
                    style={{
                      fontWeight: "700",
                      fontSize: "12px",
                      color: "#636D7D",
                    }}
                  >
                    {value > 12
                      ? "Lifetime Earnings Worth"
                      : `Past ${value} Months Earnings Worth`}
                  </div>
                  <div className="timeBasedEarnings-amount heading-sm">
                    {formatRewardValue(timeBasedEarnings)}
                  </div>
                </div>
              )}

              <div className="note-section-div">
                <hr className="note-section-hr-line"></hr>

                <div className="note-section">
                  <img className="note-section-info-icon" src={infoIcon} />
                  <span className="note-section-info-text">
                    {selected == "PRIORITY"
                      ? "Transactions update once every quarter"
                      : "Transactions update on 2nd of each month"}
                  </span>
                </div>
              </div>

              {transactions.length > 0 ? (
                transactions.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      textAlign: "left",
                      padding: "16px",
                      backgroundColor: "#fff",
                    }}
                  >
                    <div
                      style={{ width: "75%" }}
                      onClick={() => setDescOpen(!descOpen)}
                    >
                      <div className="text-desc">
                        {descOpen
                          ? item.description
                          : truncate(item.description, 40)}
                      </div>
                      <div className="text-desc" style={{ fontWeight: "500" }}>
                        {moment(item.postingDate).format("DD MMM 'YY")}
                      </div>
                    </div>
                    <div
                      className="text-desc"
                      style={{
                        width: "120px",
                        textAlign: "right",
                        fontWeight: "500",
                        fontSize: "14px",
                        color:
                          item.moneyMovementIndicator === "cr"
                            ? "#4CAD6D"
                            : "#23212C",
                      }}
                    >
                      {item.moneyMovementIndicator === "cr"
                        ? `+ ${formatRewardValue(
                            parseFloat(item.value),
                            BenefitTypes.CASHBACK_DISCOUNT.value
                          )}`
                        : `- ${formatRewardValue(
                            parseFloat(item.value),
                            BenefitTypes.CASHBACK_DISCOUNT.value
                          )}`}
                    </div>
                  </div>
                ))
              ) : (
                <div
                  style={{
                    width: "inherit",
                    textAlign: "left",
                    padding: "16px",
                    backgroundColor: "#fff",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      margin: "40% 0",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        padding: "10px",
                        borderRadius: "100%",
                        border: "2px solid #000",
                      }}
                    >
                      <img src={cb} height={"32px"} width={"32px"} />
                    </div>
                    <div
                      style={{
                        marginTop: "8px",
                        fontSize: "20px",
                        fontFamily: "Quicksand",
                        fontWeight: "700",
                        lineHeight: "28px",
                        textAlign: "center",
                      }}
                    >
                      No Transaction has been done in this window
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        {transactions.length > 0 && !loading && (
          <div className="transactions_end">End Of History</div>
        )}
      </>
    );
  };

  return (
    <>
      {!viewHistoryEnabled || selected == "PRIORITY" ? (
        <div className="main">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              maxWidth: "450px",
              backgroundColor: "#FAD7D7",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "16px",
              }}
            >
              <div style={{ margin: "0 8px" }} onClick={() => navigate(-1)}>
                <img src={left} height={"18px"} width={"18px"} alt="" />
              </div>
              <div className="header">Lounge Benefits</div>
            </div>
            <div></div>
          </div>

          <>
            <div
              style={{
                height: "60px",
                width: "100%",
                maxWidth: "450px",
                backgroundColor: "#FAD7D7",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  marginTop: "15%",
                  background: "#ffffff",
                  boxShadow: " 0px 2px 4px rgba(0, 0, 0, 0.08)",
                  width: "80%",
                  padding: "20px",
                  borderRadius: "8px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingBottom: "6px",
                  }}
                >
                  <div
                    style={{
                      background: "#ffffff",
                      display: "flex",
                      alignItems: "center",
                      width: "80%",
                    }}
                  >
                    <div>
                      <LoungeLogo color1={"#B55757"} />
                    </div>
                    <div style={{ marginLeft: "24px", textAlign: "left" }}>
                      <div className="desc">Lifetime earnings worth</div>
                      <div className="text-xl">
                        {formatRewardValue(
                          state.currentValue,
                          BenefitTypes.CASHBACK_DISCOUNT.value
                        )}
                      </div>
                    </div>
                  </div>
                  {Object.keys(state.metadata).length <= 1 && (
                    <></>
                    // <div
                    //   className="loung_label"
                    //   style={{
                    //     color: "#cb5353",
                    //     borderRadius: "4px",
                    //     background: "#FFEFF0",
                    //     padding: "2px 8px",
                    //     fontFamily: "Quicksand",
                    //     fontSize: "10px",
                    //     fontStyle: "normal",
                    //     fontWeight: "700",
                    //     lineHeight: "20px" /* 200% */,
                    //     textTransform: "uppercase",
                    //   }}
                    // >
                    //   {state?.metadata?.LOUNGE_INTERNATIONAL?.price
                    //     ? "PRIORITY"
                    //     : state?.metadata?.LOUNGE_DOMESTIC?.price
                    //     ? "DOM"
                    //     : "RAILWAY"}
                    // </div>
                  )}
                </div>
              </div>
            </div>

            {showToTop && (
              <div
                style={{
                  position: "fixed",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  maxWidth: "450px",
                }}
              >
                <div
                  style={{
                    padding: "8px 24px",
                    backgroundColor: "#F37435",
                    borderRadius: "32px",
                    color: "#fff",
                    fontSize: "14px",
                    marginTop: "18px",
                    fontFamily: "Quicksand",
                    fontWeight: "700",
                    lineHeight: "22px",
                    textTransform: "capitalize",
                    cursor: "pointer",
                    boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.16)",
                  }}
                  onClick={() => back()}
                >
                  Back To Top
                </div>
              </div>
            )}
            <div
              style={{
                height: isOnlyOneLounge ? "60px" : "78px",
                width: "100%",
                maxWidth: "450px",
                marginTop: "20%",
                backgroundColor: "#fff",
              }}
            >
              {Object.keys(state.metadata).length > 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "16px 16px 0 16px",
                    marginBottom: "20px",
                    marginTop: isOnlyOneLounge ? "8px" : "16px",
                    backgroundColor: "#fff",
                  }}
                >
                  {state?.metadata?.LOUNGE_DOMESTIC?.price && (
                    <div
                      onClick={() => {
                        if (selected == "DOM") {
                          return false;
                        }
                        if (!loading) {
                          setSelected("DOM");
                          setTransactions([]);
                          setLoading(true);
                          setTimeBasedEarnings(null);
                        }
                      }}
                      style={{
                        padding: "4px 8px",

                        width: `${100 / Object.keys(state.metadata).length}%`,
                        height: "36px",
                        fontSize: "12px",
                        border: isOnlyOneLounge
                          ? "none"
                          : selected === "DOM"
                          ? "1px solid #6D3078"
                          : "1px solid #EBEDF0",
                        borderRadius: "4px",
                        cursor: "pointer",
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "600",
                          color: isOnlyOneLounge
                            ? "#303030"
                            : selected === "DOM"
                            ? "#6D3078"
                            : "",
                        }}
                      >
                        Airport Lounge{" "}
                        <span
                          style={{
                            color: isOnlyOneLounge
                              ? "#636D7D"
                              : selected === "DOM"
                              ? "#6D3078"
                              : "#636D7D",
                            fontSize: isOnlyOneLounge ? "12px" : "9px",
                            fontWeight: isOnlyOneLounge ? "500" : "600",
                          }}
                        >
                          {isOnlyOneLounge && (
                            <span
                              style={{ marginRight: "7px", marginLeft: "6px" }}
                            >
                              -
                            </span>
                          )}
                          DOMESTIC
                        </span>
                      </div>
                    </div>
                  )}
                  {state?.metadata?.LOUNGE_RAILWAY?.price && (
                    <div
                      onClick={() => {
                        if (selected == "RAILWAY") {
                          return false;
                        }
                        if (!loading) {
                          setSelected("RAILWAY");
                          setTransactions([]);
                          setLoading(true);
                          setTimeBasedEarnings(null);
                        }
                      }}
                      style={{
                        padding: "4px 8px",
                        width: `${100 / Object.keys(state.metadata).length}%`,
                        height: "36px",
                        fontSize: "12px",
                        border: isOnlyOneLounge
                          ? "none"
                          : selected === "RAILWAY"
                          ? "1px solid #6D3078"
                          : "1px solid #EBEDF0",
                        borderRadius: "4px",
                        marginLeft: "8px",
                        cursor: "pointer",
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "600",
                          color: isOnlyOneLounge
                            ? "#303030"
                            : selected === "RAILWAY"
                            ? "#6D3078"
                            : "",
                        }}
                      >
                        Railway Lounge{" "}
                        <span
                          style={{
                            color: "#636D7D",
                            fontSize: isOnlyOneLounge ? "12px" : "9px",
                            fontWeight: isOnlyOneLounge ? "500" : "600",
                          }}
                        >
                          {isOnlyOneLounge && (
                            <span
                              style={{ marginRight: "7px", marginLeft: "6px" }}
                            >
                              -
                            </span>
                          )}
                          DOMESTIC
                        </span>
                      </div>
                    </div>
                  )}
                  {state?.metadata?.LOUNGE_INTERNATIONAL?.price && (
                    <div
                      onClick={() => {
                        if (selected == "PRIORITY") {
                          return false;
                        }
                        if (!loading) {
                          setSelected("PRIORITY");
                          setLoading(true);
                          setTransactions([]);
                          setTimeBasedEarnings(null);
                        }
                      }}
                      style={{
                        padding: "4px 8px",
                        width: `${100 / Object.keys(state.metadata).length}%`,
                        height: "36px",
                        fontSize: "12px",
                        border: isOnlyOneLounge
                          ? "none"
                          : selected === "PRIORITY"
                          ? "1px solid #6D3078"
                          : "1px solid #EBEDF0",
                        borderRadius: "4px",
                        cursor: "pointer",
                        marginLeft: "8px",
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "600",
                          color: isOnlyOneLounge
                            ? "#303030"
                            : selected === "PRIORITY"
                            ? "#6D3078"
                            : "",
                        }}
                      >
                        Priority Lounge{" "}
                        <span
                          style={{
                            color: isOnlyOneLounge ? "#636D7D" : "#636D7D",
                            fontSize: isOnlyOneLounge ? "12px" : "9px",
                            fontWeight: isOnlyOneLounge ? "500" : "600",
                          }}
                        >
                          {isOnlyOneLounge && (
                            <span
                              style={{ marginRight: "7px", marginLeft: "6px" }}
                            >
                              -
                            </span>
                          )}
                          INTERNATIONAL
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {selected !== "PRIORITY" && (
                <>
                  <div
                    style={{
                      fontSize: "10PX",
                      fontWeight: "500",
                      color: "#636D7D",
                      marginTop: "29px",
                      marginBottom: "-15px",
                      paddingBottom: "14px",
                      paddingTop: "18px",
                      background: "white",
                    }}
                  >
                    THIS QUARTER - {getQuarterRange()?.toUpperCase()}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        padding: "16px 16px 12px 16px",
                        margin: "12px 0",
                        background: "#fff",
                      }}
                    >
                      <div
                        style={{
                          width: "32%",
                          borderRight: "1px solid #94A0B4",
                        }}
                      >
                        <div>
                          {selected === "DOM"
                            ? state?.metadata?.LOUNGE_DOMESTIC?.totalVisits
                            : selected === "PRIORITY"
                            ? state?.metadata?.LOUNGE_INTERNATIONAL?.totalVisits
                            : state?.metadata?.LOUNGE_RAILWAY?.totalVisits}
                          {selected === "DOM" &&
                            selectedProgram?.additionalInfo?.showLoungeInfoTip && (
                              <span
                                style={{
                                  color: "rgba(243, 116, 53, 1)",
                                  fontSize: "16px",
                                }}
                              >
                                {" "}
                                *
                              </span>
                            )}
                        </div>
                        <div
                          style={{
                            color: "#636D7D",
                            textAlign: "center",
                            fontSize: "12px",
                            fontFamily: "Quicksand",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "20px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          Free Visits / Qtr
                        </div>
                      </div>
                      <div
                        style={{
                          width: "32%",
                          borderRight: "1px solid #94A0B4",
                        }}
                      >
                        <div>
                          {selected === "DOM"
                            ? state?.metadata?.LOUNGE_DOMESTIC?.availedVisits
                            : selected === "PRIORITY"
                            ? state?.metadata?.LOUNGE_INTERNATIONAL
                                ?.availedVisits
                            : state?.metadata?.LOUNGE_RAILWAY?.availedVisits}
                        </div>
                        <div
                          style={{
                            color: "#636D7D",
                            textAlign: "center",
                            fontSize: "12px",
                            fontFamily: "Quicksand",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "20px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          Availed
                        </div>
                      </div>
                      <div style={{ width: "32%" }}>
                        <div>
                          {selected === "DOM"
                            ? Number(
                                state?.metadata?.LOUNGE_DOMESTIC?.totalVisits -
                                  state?.metadata?.LOUNGE_DOMESTIC
                                    ?.availedVisits
                              )
                            : selected === "PRIORITY"
                            ? Number(
                                state?.metadata?.LOUNGE_INTERNATIONAL
                                  ?.totalVisits -
                                  state?.metadata?.LOUNGE_INTERNATIONAL
                                    ?.availedVisits
                              )
                            : Number(
                                state?.metadata?.LOUNGE_RAILWAY?.totalVisits -
                                  state?.metadata?.LOUNGE_RAILWAY?.availedVisits
                              )}
                        </div>
                        <div
                          style={{
                            color: "#636D7D",
                            textAlign: "center",
                            fontSize: "12px",
                            fontFamily: "Quicksand",
                            fontStyle: "normal",
                            fontWeight: "500",
                            lineHeight: "20px",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          Available
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "16px 16px 12px 16px",
                  margin: "12px 0",
                  background: "#fff",
                  marginTop: "0px",
                  marginTop: selected == "PRIORITY" ? "28px" : "12px",
                }}
              >
                <div style={{ display: "flex" }}>
                  <div className="note_left">
                    <img src={noteStar} />
                  </div>
                  <div className="note_center">
                    View list of eligible lounges
                  </div>
                  <div
                    onClick={() =>
                      window.open(
                        selected === "PRIORITY"
                          ? LOUNGE_LIST_URL.PRIORITY
                          : selected === "DOM"
                          ? LOUNGE_LIST_URL.DOMESTIC_ARPPORT
                          : LOUNGE_LIST_URL.DOMESTIC_RAILWAY
                      )
                    }
                    className="note_right"
                  >
                    here
                  </div>
                </div>
              </div>

              {selected === "DOM" &&
                selectedProgram?.additionalInfo?.showLoungeInfoTip && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      padding: "16px 16px 12px 16px",
                      margin: "12px 0",
                      background: "#fff",
                      marginTop: "0px",
                      marginTop: selected == "PRIORITY" ? "28px" : "12px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="note_left">
                        <img src={giftBoxLogo} />
                      </div>
                      <span
                        style={{
                          color: "rgba(243, 116, 53, 1)",
                          fontSize: "16px",
                          alignSelf: "flex-start",
                        }}
                      >
                        *&nbsp;
                      </span>
                      <div className="lounge_info">
                        {" "}
                        Unlock 2 lounge visits every quarter by spending
                        Rs.30,000 & above in previous quarter.
                      </div>
                    </div>
                  </div>
                )}
              {!(viewHistoryEnabled || selected == "PRIORITY") && (
                <div
                  style={{
                    background: "white",
                    paddingTop: "20px",
                    paddingBottom: "100%",
                  }}
                >
                  <div
                    className="lounge-view-visit-history-btn"
                    onClick={() => setViewHistoryEnabled(true)}
                  >
                    View Visit History
                    <span>
                      <img
                        style={{
                          height: "12px",
                          marginLeft: "6px",
                          marginBottom: "-2px",
                        }}
                        src={rightIcon}
                      />
                    </span>
                  </div>
                </div>
              )}

              {viewHistoryEnabled ||
                (selected == "PRIORITY" && <>{transactionsSectionContent()}</>)}
            </div>
          </>
        </div>
      ) : (
        <div
          className="view-history-main"
          style={{ width: "100%", maxWidth: "450px" }}
        >
          <div className="view-history-page-header-div">
            <div>
              <span
                className="view-history-back"
                onClick={() => setViewHistoryEnabled(false)}
              >
                <img src={left} />
              </span>
              <span className="view-history-heading">
                {selected == "RAILWAY" ? "Railway Lounge" : "Airport Lounge"}
              </span>
            </div>

            <div className="view-history-domestic-text">DOMESTIC</div>
          </div>

          <>{transactionsSectionContent()}</>
        </div>
      )}
    </>
  );
};

export default Lounge;
