import React from "react";
import "./styles.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentOffer } from "../../store/actions/Session";
import clock from "../../assets/images/orangeClock.svg";
import { truncate } from "../../utils/functions";
const DisplayOfferCard = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div>
      <div
        class="offerscard"
        onClick={() => {
          navigate("/offerDetails");
          dispatch(setCurrentOffer({ currentOffer: props.data }));
        }}
        style={{ cursor: "pointer" }}
      >
        <div style={{ display: "flex" }}>
          <img width="24px" height="24px" src={props.data.illustrationUrl} />
          <div class="title">{truncate(props.data.name, 22)}</div>
        </div>
        <div>
          <div
            style={{
              marginTop: "18px",
              textAlign: "left",
              fontWeight: "400",
              color: "rgba(48, 48, 48, 0.8)",
              fontSize: "13px",
            }}
          >
            {truncate(props.data.description, 80)}
          </div>
        </div>
        <div className="expiry_container" style={{ marginTop: "12px" }}>
          <div className="expiry_img">
            <img src={clock} />
          </div>
          <div className="expiry_text">
            {`Valid till ${moment(props.data.benefitExpiry).format("DD MMM")}`}
          </div>
        </div>
      </div>
      {/* <div
        id="offerscard"
        class="offerscard"
        
      >
        <div id="frame427318988" class="frame427318988">
          <div id="frame427319237" class="frame427319237">
            <div id="frame427319248" class="frame427319248">
              <img
                id="frame427318976"
                class="frame427318976"
                src={props.data.illustrationUrl}
              />
              <div
                id="makemytrip"
                class="makemytrip"
                style={{ fontWeight: "700" }}
              >
                {props.data.name}
              </div>
            </div>
            <div
              id="upto50%offonflights,hotelsandholidaybookings."
              class="upto50%offonflights,hotelsandholidaybookings."
              style={{
                textAlign: "left",
                fontWeight: "400",
                color: "rgba(48, 48, 48, 0.8)",
                fontSize: "13px",
              }}
            >
              {props.data.description}
            </div>
          </div>
          <div
            id="frame427319238"
            class="frame427319238"
            style={{ marginTop: "12px" }}
          >
            <div id="frame427319164" class="frame427319164">
              <img src={clock} />
            </div>
            <div
              id="validtill30thapril"
              class="validtill30thapril"
              style={{ marginLeft: "4px" }}
            >
              {`Valid till ${moment(props.data.benefitExpiry).format(
                "DD MMM"
              )}`}
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default DisplayOfferCard;
